import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Input, Select } from '../../components';
import { DeleteModal } from '../../components';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';
import CorectifModal from './CorectifModal';
import RapportIntervention from './RapportIntervention';
function Correctives() {
  const { t } = useTranslation();

  const [CorrectifModal, setCorrectifModal] = useState(false)
  const [CorrectifId, setCorrectifId] = useState(null)
  const [zones, setZones] = useState([])

  const { currentUser } = useCurrentUser();
  const { checkAccess } = useAccessChecker();

  const [searchValue, setSearchValue] = useState(null)

  const { id } = useParams()



  const [formData, setFormData] = useState({ agence: null, technicien: null, nature: null, motif: null, status: null, traveaux: null, probleme: null, comment: null, Machine: null, typeMachine: null })
  const [loading, setLoding] = useState(false)
  const [Correctif, setCorrectif] = useState([])
  const [done, setDone] = useState(false)
  const [ImportModal, setImportModal] = useState(false)
  const [filterModal, setFilterModal] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)
  const [agences, setAgences] = useState([])
  const [prestations, setPrestation] = useState([])
  const [machines, setMachines] = useState([])

  const [techniciens, setTechniciens] = useState([])
  const [filter, setFilter] = useState({ zone: null, departement: null, agence: null, status: null, manager: null, date: null, Machine: null, typeMachine: null })
  const [departements, setDepartements] = useState([])


  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // change this as needed
  const pageCount = Math.ceil(Correctif.length / itemsPerPage);
  const displayedItems = Correctif.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const statusAdmin = [{ name: t('Non Traité'), _id: 'Non Traité' }, { name: t('En cours'), _id: 'En cours' }, { name: t('Traité'), _id: 'Traité' }]
  const MachiesTypes = [{ name: t('TraitementDeau'), _id: 'TraitementDeau' }, { name: t('Generateur'), _id: 'Generateur' }, { name: t('Autres'), _id: 'Autres' }]

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoding(true);
        await Promise.all([
          getCorrectif(filter),
          getAgences(),
          getTechniciens(),
          getZones(),
          getPrestation()
        ]);
        setLoding(false);
      } catch (error) {
        toast.error(error.response.data.message);
        setLoding(false);
      }
    };

    fetchData();
  }, [id, done]);




  const getCorrectif = async (filters) => {
    try {
      let response;
      setLoding(true)
      if (id) response = await axiosInstance.get(`/correctif/get/${id}`);
      else response = await axiosInstance.post(`/correctif/search`, filters);
      const responseData = response.data
      if (responseData && responseData.statusCode === 200) {
        if (id) {
          setCorrectif([responseData.data])
        }
        else setCorrectif(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };


  const handleChange = async (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.name === "typeMachine") {

      if (e.target.value === 'Generateur') await fetchDataPost('/generateur/search', setMachines);
      else if (e.target.value === 'TraitementDeau') await fetchDataPost('/traitementDeau/search', setMachines);

    }
  }
  const handleChangeFilter = async (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
    if (e.target.name === "typeMachine") {

      if (e.target.value === 'Generateur') await fetchDataPost('/generateur/search', setMachines);
      else if (e.target.value === 'TraitementDeau') await fetchDataPost('/traitementDeau/search', setMachines);

    }
  }
  const hanldleZoneChange = (e) => {
    const value = e.target.value;
    const zone = zones.find(item => item._id === value)
    let departements = []
    if (zone) {
      setFilter({ ...filter, [e.target.name]: value });
      zone.departements.forEach(item => {
        departements.push({ _id: item, name: item })
      })
    }

    setDepartements(departements)
  }



  const InitialiseCorrectif = () => {
    setFormData({ agence: null, technicien: null, nature: null, motif: null, status: null, traveaux: null, probleme: null, comment: null, Machine: null, typeMachine: null })
  }

  const reset = () => {
    setSearchValue('')
    getCorrectif({ zone: null, agence: null, status: null, technicien: null, date: null, Machine: null, typeMachine: null })
    setFilter({ zone: null, agence: null, status: null, technicien: null, date: null, Machine: null, typeMachine: null })

  }


  const fetchDataPost = async (url, setter) => {
    try {
      setLoding(true);
      const response = await axiosInstance.post(url, { agence: formData.agence || filter.agence });
      const responseData = response.data;

      if (responseData && responseData.statusCode === 200) {
        setter(responseData.data);
      }
      setLoding(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoding(false);
    }
  };



  const handleToggleModal = async (id, name) => {
    if (id) {
      if (name === 'import') {
        setImportModal(true)
        setCorrectifId(id)
        await getCorrectifToUpdate(id)
        setCorrectifModal(!CorrectifModal)

      } else {
        setCorrectifId(id)
        await getCorrectifToUpdate(id)
        setCorrectifModal(!CorrectifModal)

      }

    } else {
      setCorrectifModal(!CorrectifModal)
      setCorrectifId(null)
      setProgress(0)
      InitialiseCorrectif()
      setFile(null)
      setImportModal(false)

    }
  }

  const getCorrectifToUpdate = async (id) => {
    try {
      setLoding(true)
      const response = await axiosInstance.get(`/correctif/get/${id}`);
      const responseData = response.data
      if (responseData && responseData.statusCode === 200) {
        setFormData(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      setLoding(false)
    }
  }


  const save = async (e) => {
    e.preventDefault();
    try {
      setLoding(true)
      let response = null
      if (CorrectifId) {
        response = await axiosInstance.put(`/correctif/update/${CorrectifId}`, formData);
      } else {
        response = await axiosInstance.post('/correctif/create', formData);
      }
      const responseData = response.data
      if (responseData && responseData.statusCode === 200) {
        if (CorrectifId) {
          toast.success(responseData.message);
          setDone(!done)
          handleToggleModal()
        } else {
          toast.success(responseData.message);
          setDone(!done)
          handleToggleModal()
        }
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message);
      setLoding(false)
    }
  };

  const fetchData = async (url, setter) => {
    try {
      setLoding(true);
      const response = await axiosInstance.get(url);
      const responseData = response.data;

      if (responseData && responseData.statusCode === 200) {
        setter(responseData.data);
      }
      setLoding(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoding(false);
    }
  };

  const getAgences = async () => {
    await fetchData('/agences/search', setAgences);
  };

  const getTechniciens = async () => {
    await fetchData('/users/searchManagers', setTechniciens);
  };

  const getPrestation = async () => {
    await fetchData('/prestation/search', setPrestation);
  };



  const getZones = async () => {
    await fetchData('/zones/search', setZones);
  };



  const find = async (e) => {
    try {
      e.preventDefault()
      setLoding(true)
      const response = await axiosInstance.post(`/correctif/find`, { searchValue });
      const responseData = response.data

      if (responseData && responseData.statusCode === 200) {
        setCorrectif(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };


  const checkColor = (status) => {
    switch (status) {
      case 'Non Traité':
        return 'dark:bg-red-600 dark:bg-opacity-10  bg-red-100 text-red-600 ';
      case 'En cours':
        return 'dark:bg-yellow-600 dark:bg-opacity-10  bg-yellow-100 text-yellow-600';
      case 'En cours de validation':
        return 'dark:bg-orange-600 dark:bg-opacity-10  bg-orange-100 text-orange-600';
      case 'Traité':
        return 'dark:bg-green-600 dark:bg-opacity-10  bg-green-100 text-green-600';
      default:
        return 'dark:bg-red-600 dark:bg-opacity-10  bg-red-100 text-red-600'; // Replace with your actual default color class or value
    }
  };


  const [file, setFile] = useState(null);
  const [progress, setProgress] = useState(0);


  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      if (selectedFile.size > 15 * 1024 * 1024) {
        toast.error('File size exceeds 15 MB limit');
        return;
      }

      const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!allowedTypes.includes(selectedFile.type)) {
        toast.warning('Only Word (doc, docx) and PDF files are allowed.');
        return;
      }

      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = () => {
        setFormData({ ...formData, facture: reader.result });
        setFile(selectedFile);
      };
    }
  };



  const handleUpload = (e) => {
    if (!file) {
      toast.warning('Please select a file to upload');
      return;
    }
    let currentProgress = 0;
    const interval = setInterval(() => {
      currentProgress += 10;
      setProgress(currentProgress);
      if (currentProgress >= 100) {
        clearInterval(interval);
        save(e);
      }
    }, 200);
  };

  const formatDate = (date) => {
    if (!date) return '';

    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit' };

    const formattedDate = new Date(date).toLocaleDateString('en-US', dateOptions);
    const formattedTime = new Date(date).toLocaleTimeString('en-US', timeOptions);

    return `${formattedDate}, ${formattedTime}`;
  };




  return (
    <div className="flex flex-col w-[100%] justify-start p-6   h-[100vh]">
      <div className="sm:flex sm:items-center mb-6 sm:justify-between">
        <div className='flex flex-col justify-center items-start'>
          <div className="flex items-center gap-x-3">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Correctif")}</h2>
            <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-neutral-800 dark:text-blue-400 lowercase">{Correctif.length} {t("Correctif")}</span>
          </div>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-300"> {t('correctif description')} </p>
        </div>
        <div className="flex items-center mt-4 gap-x-3">
          {checkAccess('correctives', 'create') &&

            <button onClick={() => { handleToggleModal() }} className="flex items-center justify-center w-1/2 px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>{t("Add Correctif")}</span>
            </button>
          }
        </div>
      </div>
      <div className="flex flex-col max-w-full ">
        <div className="-m-1.5 ">
          <div className=" w-full  inline-block align-middle">
            <div className="border rounded-lg divide-y overflow-hidden max-w-full  bg-white dark:bg-neutral-800 divide-neutral-200 dark:border-neutral-700 dark:divide-neutral-700">
              <div className="py-3 px-4  ">
                <div className='flex justify-start items-center gap-2'>
                  <div className="relative md:w-1/2 w-full">
                    <label htmlFor="hs-table-search" className="sr-only">{t("Search")}</label>
                    <input type="search" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} name="hs-table-search" id="hs-table-search" className="py-2 cursor-pointer px-3 ps-9 block w-full border border-neutral-200 bg-neutral-50 h-14 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" placeholder={t("Search for items")} />
                    <a onClick={(e) => reset(e)} className="absolute top-4 left-2 z-50 flex items-center ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 mr-2 z-45  text-gray-800 dark:text-neutral-100">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>


                    </a>
                    <button onClick={(e) => find(e)} disabled={!searchValue} className="block h-10 absolute top-2 right-2 flex items-center  justify-center  md:px-6 px-3 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg   dark:bg-neutral-100 lowercase">{t("Search")}</button>

                  </div>
                  <button onClick={() => setFilterModal(!filterModal)} className={` flex justify-center hover:scale-105 transition-all duration-400 items-center p-3 text-white  bg-black dark:text-black rounded-lg   dark:bg-neutral-100 `}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                    </svg>


                  </button>
                </div>

                <AnimatePresence>

                  {filterModal &&
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className='flex gap-3 md:flex-row flex-col transition-all duration-300 justify-start mt-3 items-center'>

                      <Select label={t("Zone")} disabled={false} options={zones} name="zone" handleChange={hanldleZoneChange} defaultV={filter.zone || null} />
                      <Select label={t("Departement")} disabled={departements.length > 0 ? false : true} options={departements} name="departement" handleChange={handleChangeFilter} defaultV={filter.departement || null} />
                      <Select label={t("Agence")} options={agences} name="agence" handleChange={handleChangeFilter} defaultV={filter.agence} />
                      <Select label={t("Type Machine")} disabled={!filter.agence} options={MachiesTypes} name="typeMachine" handleChange={handleChangeFilter} defaultV={filter.typeMachine || null} />
                      <Select label={t("Machine")} disabled={filter.typeMachine === "Autres" || !filter.typeMachine} options={machines} name="Machine" handleChange={handleChangeFilter} defaultV={filter.Machine || null} />
                      <Select label={t("Technicien")} options={techniciens} name="technicien" handleChange={handleChangeFilter} defaultV={filter.technicien} />
                      <Select label={t("Status")} options={statusAdmin} name="status" handleChange={handleChangeFilter} defaultV={filter.status} />

                      <div className='flex justify-center items-center gap-3 w-full px-1'>
                        <button onClick={() => { getCorrectif(filter) }} className="flex items-center w-1/2 justify-center gap-2  px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg   dark:bg-neutral-100">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                          </svg>

                          <span>{t("Filter")}</span>
                        </button>
                        <button onClick={() => { reset() }} className="flex items-center gap-2 w-1/2 justify-center  px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg   dark:bg-neutral-100">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                          </svg>


                          <span>{t("Reset")}</span>
                        </button>
                      </div>
                    </motion.div>

                  }

                </AnimatePresence>
              </div>
              <div className="overflow-x-auto max-w-full">
                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700">
                  <thead className=" bg-neutral-50 dark:bg-neutral-900 h-14">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Ticket")}</th>

                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Centre")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Nature")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Motif")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Probleme")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Traveaux")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Technicien")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Comment")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Bon d'intervention")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Date")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Status")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Actions")}</th>

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-neutral-200 dark:divide-neutral-700">
                    {displayedItems.length > 0 ?
                      displayedItems.map(item => {
                        return (
                          <tr key={item}>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm font-medium text-gray-800 dark:text-gray-200">{item.reference || '-----'}</td>

                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm font-medium text-gray-800 dark:text-gray-200">{item.agence.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.nature.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200 max-w-40 truncate">{item.motif}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm font-medium text-gray-800 dark:text-gray-200 max-w-40 truncate">{item.probleme}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200 max-w-40 truncate">{item.traveaux}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.technicien?.fullName || 'None'}</td>

                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200 max-w-40 truncate">{item.comment || 'None'}</td>

                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">
                              {item.facture ?
                                <a href={item.facture} target='_blank' className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-file-down"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" /><path d="M14 2v4a2 2 0 0 0 2 2h4" /><path d="M12 18v-6" /><path d="m9 15 3 3 3-3" /></svg>                                                                </a>

                                :
                                'None'

                              }
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm font-medium text-gray-800 dark:text-gray-200">
                              <div className={`flex justify-center items-center w-fit text-[13px] px-6 gap-1 ${checkColor('Traité')}  rounded-full  py-2`}>
                                {formatDate(item.createdAt)}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm font-medium text-gray-800 dark:text-gray-200">
                              <div className={`flex justify-center items-center w-fit text-[13px] px-6 gap-1 ${checkColor(item.status)}  rounded-full  py-2`}>
                                {t(item.status)}
                              </div>
                            </td>


                            <td className="px-6 py-4 whitespace-nowrap text-start text-end text-sm font-medium">
                              <div className='flex justify-start items-center gap-2'>
                                {checkAccess('correctives', 'delete') &&
                                  <a onClick={() => { setCorrectifId(item._id); setDeleteModal(true) }} title='Delete Correctif' className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-red-600 dark:bg-opacity-10 rounded-full bg-red-100 text-red-600'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                  </a>
                                }
                                {checkAccess('correctives', 'update') &&
                                  <>
                                    <a onClick={() => handleToggleModal(item._id)} title='Update Correctif' className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-100 text-green-600'>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                    </a>
                                    <a onClick={() => handleToggleModal(item._id, 'import')} title='Upload Facture' className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600'>
                                      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-file-symlink"><path d="m10 18 3-3-3-3" /><path d="M14 2v4a2 2 0 0 0 2 2h4" /><path d="M4 11V4a2 2 0 0 1 2-2h9l5 5v13a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h7" /></svg>                                </a>
                                  </>
                                }

                                <CorectifModal correctif={item} done={done} setDone={setDone} />

                                <RapportIntervention data={item} />

                              </div>


                            </td>
                          </tr>
                        )
                      })
                      :
                      <tr className="w-full">
                        <td colSpan="10" className="px-6 py-14 animate-pulse  text-gray-500 dark:text-gray-500 text-3xl text-center font-medium">
                          {t("There is no data to be displayed !!!!")}
                        </td>
                      </tr>

                    }


                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="relative my-6">
            {displayedItems.length > 0 &&

              <ReactPaginate
                pageCount={pageCount}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName=" w-full flex justify-center  items-center "
                pageClassName="border dark:border-neutral-700 flex justify-center items-center ml-2 w-8  h-8 rounded-full"
                activeClassName="bg-green-500 text-white"
                previousClassName="bg-white dark:bg-neutral-900 dark:text-white border flex justify-center items-center text-neutral-800 hover:text-green-500 border-gray-200 dark:border-neutral-700 absolute left-0 px-6 py-2 rounded-full"
                nextClassName="bg-white dark:bg-neutral-900 dark:text-white border flex justify-center items-center text-neutral-800 hover:text-green-500 border-gray-200 dark:border-neutral-700 absolute right-0   px-6 py-2 rounded-full"
                disabledClassName="opacity-50 cursor-not-allowed"
                breakClassName="mx-2"
                pageLinkClassName="hover:text-green-500"
                previousLinkClassName="hover:text-green-500"
                nextLinkClassName="hover:text-green-500"
                breakLinkClassName="hover:text-green-500"
              />
            }
          </div>
        </div>
      </div>




      <AnimatePresence>
        {ImportModal &&
          <div
            className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-80 inset-0 z-0" />
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              className="w-full md:w-[30%]  relative mx-auto mx-2  rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
              <div className="shadow-lg rounded-lg p-6 relative">
                <div className="flex items-center pb-3 border-b border-gray-200 dark:border-neutral-700">
                  <div className="flex-1">
                    <h3 className="text-neutral-800 dark:text-neutral-200 text-xl font-bold">{t("Upload Facture")}</h3>
                    <p className="text-neutral-600 dark:text-neutral-400 text-xs mt-1">{t("Upload facture to this project")}</p>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-3 ml-2 cursor-pointer shrink-0 fill-gray-400 hover:fill-red-500"
                    viewBox="0 0 320.591 320.591"
                    onClick={() => { handleToggleModal() }}
                  >
                    <path
                      d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                      data-original="#000000"
                    />
                    <path
                      d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                      data-original="#000000"
                    />
                  </svg>
                </div>


                <div className="rounded-lg border-2 border-gray-200 dark:border-neutral-700 border-dashed mt-6">
                  <div className="p-4 min-h-[180px] flex flex-col items-center justify-center text-center cursor-pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="w-10 mb-4 fill-neutral-700 dark:fill-neutral-300  inline-block"
                      viewBox="0 0 32 32"
                    >
                      <path
                        d="M23.75 11.044a7.99 7.99 0 0 0-15.5-.009A8 8 0 0 0 9 27h3a1 1 0 0 0 0-2H9a6 6 0 0 1-.035-12 1.038 1.038 0 0 0 1.1-.854 5.991 5.991 0 0 1 11.862 0A1.08 1.08 0 0 0 23 13a6 6 0 0 1 0 12h-3a1 1 0 0 0 0 2h3a8 8 0 0 0 .75-15.956z"
                        data-original="#000000"
                      />
                      <path
                        d="M20.293 19.707a1 1 0 0 0 1.414-1.414l-5-5a1 1 0 0 0-1.414 0l-5 5a1 1 0 0 0 1.414 1.414L15 16.414V29a1 1 0 0 0 2 0V16.414z"
                        data-original="#000000"
                      />
                    </svg>
                    <h4 className="text-sm text-neutral-600 dark:text-neutral-400"> {t("Drag & Drop or")} <label htmlFor="chooseFile" className="text-blue-600 cursor-pointer">{t("Choose file")}</label> {t("to upload")}
                    </h4>
                    <input
                      type="file"
                      id="chooseFile"
                      name="facture" // Ensure name attribute matches your form data structure
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                {file && (
                  <div className="flex flex-col dark:bg-neutral-900 bg-gray-50 p-4 rounded-lg mt-4">
                    <div className="flex items-center">
                      <p className="text-xs text-neutral-600 dark:text-neutral-400 flex-1">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="w-5 mr-2 fill-current inline-block">
                          <path
                            d="m433.798 106.268-96.423-91.222C327.119 5.343 313.695 0 299.577 0H116C85.673 0 61 24.673 61 55v402c0 30.327 24.673 55 55 55h280c30.327 0 55-24.673 55-55V146.222c0-15.049-6.27-29.612-17.202-39.954zM404.661 120H330c-2.757 0-5-2.243-5-5V44.636zM396 482H116c-13.785 0-25-11.215-25-25V55c0-13.785 11.215-25 25-25h179v85c0 19.299 15.701 35 35 35h91v307c0 13.785-11.215 25-25 25z"
                            data-original="#000000"
                          />
                          <path
                            d="M363 200H143c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15zm0 80H143c-8.284 0-15 6.716-15 15s6.716 15 15 15h220c8.284 0 15-6.716 15-15s-6.716-15-15-15zm-147.28 80H143c-8.284 0-15 6.716-15 15s6.716 15 15 15h72.72c8.284 0 15-6.716 15-15s-6.716-15-15-15z"
                            data-original="#000000"
                          />
                        </svg>
                        {file.name} <span className="ml-2">{(file.size / 1024 / 1024).toFixed(2)} MB</span>
                      </p>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-3 cursor-pointer shrink-0 fill-gray-400 hover:fill-red-500"
                        viewBox="0 0 320.591 320.591"
                        onClick={() => setFile(null)}
                      >
                        <path
                          d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                          data-original="#000000"
                        />
                        <path
                          d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                          data-original="#000000"
                        />
                      </svg>
                    </div>
                    <div className="bg-gray-300 rounded-full w-full h-2 my-2">
                      <div
                        className="w-1/3 h-full rounded-full bg-blue-600 flex items-center relative"
                        style={{ width: `${progress}%` }}
                      >
                        <span className="absolute text-xs right-0 bg-white w-2 h-2 rounded-full" />
                      </div>
                    </div>
                    <p className="text-xs text-neutral-600 dark:text-neutral-400 flex-1">{progress}% done</p>
                  </div>
                )}
                <div className="border-t border-gray-200 dark:border-neutral-700 pt-6 flex justify-center w-full gap-4 mt-6">
                  {/* <button
                    type="button"
                    className="flex items-center justify-center w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 dark:bg-neutral-100"
                    onClick={() => { handleToggleModal() }}
                  >
                    Cancel
                  </button> */}
                  <button
                    onClick={(e) => handleUpload(e)}
                    className="flex items-center justify-center w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2 dark:bg-neutral-100"
                  >
                    {!progress ? (
                      <>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                        </svg>
                        <span>{t("Upload Facture")}</span>
                      </>
                    ) : (
                      <div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white dark:text-neutral-800 rounded-full" role="status" aria-label="loading">
                        <span className="sr-only">Loading...</span>
                      </div>
                    )}
                  </button>
                </div>
              </div>

            </motion.div>
          </div>
        }
      </AnimatePresence >




      <AnimatePresence>

        {CorrectifModal && !ImportModal &&
          <div
            className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-80 inset-0 z-0" />
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              className="w-full md:w-[40%] py-4 relative mx-auto mx-2  rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
              <div className="flex  flex-col justify-center items-center w-full px-6 py-3">
                <div className="flex w-full justify-between items-center gap-x-3 mb-6">
                  <h2 className="text-[20px] font-[700] text-gray-800 dark:text-white">{!CorrectifId ? t('Add') : t('Update')} {t("Correctif")}</h2>
                  <a onClick={() => handleToggleModal()} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>
                </div>
                <div className='grid md:grid-cols-2 w-full place-items-center gap-3 '>
                  <Input type='text' disabled={formData.status === 'Traité'} label={t("Ticket")} name="reference" handleChange={handleChange} defaultV={formData.reference || null} />

                  <Select label={t("Centre")} disabled={CorrectifId || formData.status === 'Traité'} options={agences} name="agence" handleChange={handleChange} defaultV={CorrectifId ? formData.agence?._id || null : formData.agence} />
                  <Select label={t("Nature")} disabled={formData.status === 'Traité'} options={prestations} name="nature" handleChange={handleChange} defaultV={CorrectifId ? formData.nature?._id || null : formData.nature} />
                  <Input type='text' disabled={formData.status === 'Traité'} label={t("Probleme")} name="probleme" handleChange={handleChange} defaultV={formData.probleme || null} />
                  <Input type='text' disabled={formData.status === 'Traité'} label={t("Traveaux")} name="traveaux" handleChange={handleChange} defaultV={formData.traveaux || null} />
                  <Input type='text' disabled={['technicien', 'manager'].includes(currentUser.role.name.toLowerCase()) && CorrectifId || formData.status === 'Traité'} label={t("Motif")} name="motif" handleChange={handleChange} defaultV={formData.motif || null} />

                  <Select label={t("Type Machine")} disabled={CorrectifId || formData.status === 'Traité' || !formData.agence} options={MachiesTypes} name="typeMachine" handleChange={handleChange} defaultV={formData.typeMachine || null} />
                  {formData.typeMachine && (
                    formData.typeMachine === 'Autres' ? (
                      <Input
                        type='text'
                        disabled={formData.status === 'Traité'}
                        label={t("Autre Desc")}
                        name="Autre"
                        handleChange={handleChange}
                        defaultV={formData.Autre || null}
                      />
                    ) : (
                      <Select
                        label={t("Machine")}
                        disabled={CorrectifId || formData.status === 'Traité'}
                        options={machines}
                        name="Machine"
                        handleChange={handleChange}
                        defaultV={CorrectifId ? formData[formData.typeMachine] || null : formData.Machine}
                      />
                    )
                  )}


                  {currentUser.role.name.toLowerCase() !== 'technicien' &&
                    <Select label={t("Technicien")} disabled={formData.status === 'Traité'} options={techniciens} name="technicien" handleChange={handleChange} defaultV={formData.technicien || null} />

                  }
                  {CorrectifId &&
                    <>
                      <Select label={t("Status")} disabled={formData.status === 'Traité'} options={statusAdmin} name="status" handleChange={handleChange} defaultV={formData.status || null} />

                    </>

                  }
                  <Input type='text' disabled={formData.status === 'Traité'} label={t("Comment")} name="comment" handleChange={handleChange} defaultV={formData.comment || null} />


                </div>

                <div className='md:col-span-3 w-full flex items-center justify-end '>
                  <div className="flex items-center mt-6 gap-x-3">
                    <button onClick={(e) => save(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                      {
                        !loading ?
                          (
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              <span>{t("Save correctif")}</span>
                            </>
                          )
                          :

                          (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                            <span className="sr-only">Loading...</span>
                          </div>)

                      }

                    </button>
                  </div>
                </div>

              </div>
            </motion.div>
          </div>
        }
      </AnimatePresence >



      <DeleteModal setDeleteId={setCorrectifId} open={deleteModal} setOpen={setDeleteModal} DeleteId={CorrectifId} Model='correctif' setDone={setDone} setLoding={setLoding} done={done} />

    </div >


  )
}

export default Correctives