import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { useTheme } from '../../utils/context/ThemeContext';
import { useTranslation } from 'react-i18next';

const CorrectifPerPrestation = ({ data }) => {
    const { t } = useTranslation();
    const { theme } = useTheme();
    const chartRef = useRef(null);

 const monthNames = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
];


    useEffect(() => {
        // Ensure data is an array before proceeding

        if (!Array.isArray(data)) {
            console.error('Data prop is not an array:', data);
            return;
        }

        const chartDom = chartRef.current;
        const myChart = echarts.init(chartDom);

        const textColor = theme === 'dark' ? '#ffffff' : '#000000';

        // Extract unique months and prestations from the data prop
        const months = [...new Set(data.map(item => item.month))];
        const prestations = [...new Set(data.map(item => item.prestation))];

        // Prepare series data
        const seriesData = prestations.map(prestation => ({
            name: prestation,
            type: 'bar',
            data: months.map(month => {
                const dataPoint = data.find(item => item.prestation === prestation && item.month === month);
                return dataPoint ? dataPoint.count : 0;
            })
        }));

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                textStyle: {
                    color: textColor
                }
            },
                itemStyle: {
    borderRadius: [10, 10, 0, 0] 
},
            xAxis: {
                type: 'category',
                data: months.map(month => monthNames[month - 1]),
                axisTick: { show: false },
                axisLabel: {
                    color: textColor
                }
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: textColor
                }
            },
            series: seriesData,
                 color:["#115f9a",  "#22a7f0","#76c68f",  "#c9e52f", "#d0ee11", "#d0f400"]

        };

        myChart.setOption(option);

        // Handle window resize
        const handleResize = () => {
            myChart.resize();
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            myChart.dispose();
        };
    }, [theme, data]);

    return <div ref={chartRef} style={{ width: '100%', height: '550px' }}></div>;
};

export default CorrectifPerPrestation;
