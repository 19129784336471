import React, { useEffect, useRef, useState } from 'react';
import html2pdf from 'html2pdf.js';
import './Raport.css';
import logo from '../../assets/images/logo.png';

const RapportIntervention = ({ data }) => {
    const reportRef = useRef();


 

    const handleDownload = async () => {
        const element = reportRef.current;
        const opt = {
            margin: 0,
            filename: `Rapport_${data.reference}_${data.agence?.name}.pdf`,
            image: { type: "jpeg", quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        };

        html2pdf().from(element).set(opt).save();
    };




    const formatDate = (date) => {
        if (!date) return '';

        const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };

        const formattedDate = new Date(date).toLocaleDateString('en-US', dateOptions);

        return `${formattedDate}`;
    };
    const formatHeure = (date) => {
        if (!date) return '';

        const timeOptions = { hour: '2-digit', minute: '2-digit' };

        const formattedTime = new Date(date).toLocaleTimeString('en-US', timeOptions);

        return `${formattedTime}`;
    };

    return (
        <div>
            <div style={{ display: "none" }}>

                <div ref={reportRef} className="facture">
                    <header>
                        <div className="logo">
                            <img src={logo} alt="Logo" />
                        </div>
                        <div className="">
                            <h2 style={{ fontSize: "30px" }}><strong>Solution en hémodialyse et en Électricité</strong></h2>
                        </div>
                    </header>

                    <section className="header" style={{ height: "5rem" }}>
                        <div className="heder-title">
                            <h3> <strong>RAPPORT D'INTERVENTION</strong> </h3>
                            <span><b>N°</b>: {data.reference}</span>
                        </div>
                        <div className="rapport-info">
                            <div className="rapport-text">
                                <div className="box">
                                    {data.typeMachine === 'Generateur' &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                            className="lucide lucide-check">
                                            <path d="M20 6 9 17l-5-5" />
                                        </svg>
                                    }

                                </div>
                                <p><strong>Hémodialyse</strong></p>
                            </div>
                            <div className="rapport-text">
                                <div className="box">
                                    {data.typeMachine === 'TraitementDeau' &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                            className="lucide lucide-check">
                                            <path d="M20 6 9 17l-5-5" />
                                        </svg>
                                    }

                                </div>
                                <p><strong>Traitement D'eau</strong></p>
                            </div>
                            <div className="rapport-text">
                                <div className="box">
                                    {data.typeMachine === 'Autres' &&
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none"
                                            stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                            className="lucide lucide-check">
                                            <path d="M20 6 9 17l-5-5" />
                                        </svg>
                                    }

                                </div>
                                <p><strong>Autres:</strong> {data.typeMachine === 'Autres' ? data.Autre || '------------' : '----------'}</p>
                            </div>
                        </div>
                    </section>

                    <section className="client-info" style={{ height: "10rem" }}>
                        <div className="left">
                            <p><strong>Client:</strong> {data.agence?.name || '-----------'}</p>
                            <p><strong>Service:</strong> {data.nature?.name || '-----------'}</p>
                            <p><strong>Responsable:</strong> ----------</p>
                            <p><strong>Adresse:</strong>{data.agence?.departement || '-----------'}</p>
                        </div>
                        <div className="left">
                            <p><strong>Date:</strong> {formatDate(data.updatedAt)} </p>
                            <p><strong>Heure :</strong> {formatHeure(data.updatedAt)}</p>
                        </div>
                        <div className="right">
                            <p><strong>Matériel:</strong> {data[data.typeMachine]?.reference || '-----------'}</p>
                            <p><strong>Marque:</strong> {data[data.typeMachine]?.marque || '-----------'}</p>
                            <p><strong>SN°:</strong> {data[data.typeMachine]?.serie || '-----------'}</p>
                            <p><strong>N° D'heures:</strong> {data[data.typeMachine]?.duration || '-----------'}</p>
                        </div>
                    </section>

                    <section className="header-type" style={{ height: "3rem" }}>
                        <div className="heder-title">
                            <p><strong>Type de contrat:</strong> Correctif </p>
                        </div>
                    </section>

                    <section className="details">
                        <p><strong>Problème:</strong> {data.probleme || '-----------'}</p>
                        <p><strong>Travaux effectués:</strong> {data.traveaux || '-----------'}</p>
                        <p><strong>Remarque:</strong> {data.comment || '-----------'}</p>
                    </section>

                    <section className="items">
                        <table>
                            <thead>
                                <tr>
                                    <th>Qté</th>
                                    <th>Désignation</th>
                                    <th>Réf</th>
                                    <th>Qté</th>
                                    <th>Désignation</th>
                                    <th>Réf</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data.consomables?.reduce((rows, item, index) => {
                                    // Check if the current index is even
                                    if (index % 2 === 0) {
                                        rows.push([item]);
                                    } else {
                                        rows[rows.length - 1].push(item);
                                    }
                                    return rows;
                                }, []).map((rowItems, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {rowItems.map((item, itemIndex) => (
                                            <React.Fragment key={itemIndex}>
                                                <td>{item.quantity}</td>
                                                <td>{item.article.name}</td>
                                                <td>{item.article.reference}</td>
                                            </React.Fragment>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </section>

                    <footer >
                        <div className="signature" style={{ height: "7rem" }}>
                            <div className="sub-sign">
                                <p><strong>Client (Signature + Cachet):</strong></p>
                            </div>
                            <div className="sub-sign">
                                <p><strong>Service Après Vente (Signature + Cachet):</strong> {data.technicien ? data.technicien?.fullName : '---------'}</p>
                            </div>
                        </div>
                        <div className="company-info">
                            <p>S.A.R.L au capital de 1 000 000,00 Dirhams - ICE: 00003407000058</p>
                            <p>Rib C.B : 101010212114250380000055 (B.P) - Siege Social : Lotissement Amsernat Lot 34 - AGADIR</p>
                            <p>R.C N° : 27035 - Taxe Professionnelle : 48158206 - I.F : 14493009 - CNSS : 4046828</p>
                            <p>Tél: 05 28 29 29 77 - E-mail: moroccodialysis@gmail.com</p>
                        </div>
                    </footer>
                </div>

            </div>

            <button onClick={handleDownload} title="Download Rapport D'intervention" className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-orange-600 dark:bg-opacity-10 rounded-full bg-orange-100 text-orange-600'>
                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-file-down">
                    <path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" />
                    <path d="M14 2v4a2 2 0 0 0 2 2h4" />
                    <path d="M12 18v-6" />
                    <path d="m9 15 3 3 3-3" />
                </svg>
            </button>
        </div>
    );
};

export default RapportIntervention;
