import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { DeleteModal } from '../../components';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { useAccessChecker } from '../../utils/Functions/Authorization';

import { useTranslation } from 'react-i18next';

function Agences() {
  const { t } = useTranslation();

  const { checkAccess } = useAccessChecker();

  const [loading, setLoding] = useState(false)

  const [agences, setAgences] = useState([])
  const [agenceSelected, setAgenceSelected] = useState(null)


  const [agenceId, setAgenceId] = useState(null)
  const [done, setDone] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const [searchValue, setSearchValue] = useState(null)

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoding(true);
        const response = await axiosInstance.get('/agences/search');
        const responseData = response.data;

        if (responseData && responseData.statusCode === 200) {
          setAgences(responseData.data);
        }
        setLoding(false);
      } catch (error) {
        toast.error(error.response.data.message);
        setLoding(false);
      }
    };

    fetchData();

  }, [done]);


  const reset = () => {
    setSearchValue('')
    setDone(!done)
  }
  const find = async (e) => {
    try {
      e.preventDefault()
      setLoding(true)
      const response = await axiosInstance.post(`/agences/find`, { searchValue });
      const responseData = response.data

      if (responseData && responseData.statusCode === 200) {
        setAgences(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };

  return (
    <div className="flex flex-col w-[100%] justify-start p-6  h-[100vh]">
      <div className="sm:flex sm:items-center mb-6 sm:justify-between">
        <div className='flex flex-col justify-center items-start'>
          <div className="flex items-center gap-x-3">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Centres")}</h2>
            <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-neutral-800 dark:text-blue-400">{agences.length} {t("Centres")}</span>
          </div>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-300"> {t("Centres description")} </p>
        </div>
        <div className="flex items-center mt-4 gap-x-3">
          {checkAccess('agences', 'create') &&
            <Link to='/Agences/new' className="flex items-center justify-center w-1/2 px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span>{t("Add Centre")}</span>
            </Link>
          }

        </div>
      </div>
      <div className="relative md:w-1/2 w-full">
        <label htmlFor="hs-table-search" className="sr-only">{t("Search")}</label>
        <input type="search" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} name="hs-table-search" id="hs-table-search" className="py-2 cursor-pointer px-3 ps-9 block w-full border border-neutral-200 bg-neutral-50 h-14 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" placeholder={t("Search for items")} />
        <a onClick={(e) => reset(e)} className="absolute top-4 left-2 z-50 flex items-center ">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 mr-2 z-45  text-gray-800 dark:text-neutral-100">
            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
          </svg>


        </a>
        <button onClick={(e) => find(e)} disabled={!searchValue} className="block h-10 absolute top-2 right-2 flex items-center  justify-center  md:px-6 px-3 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg   dark:bg-neutral-100 lowercase ">{t("Search")}</button>

      </div>

      <div className='w-full py-6 grid md:grid-cols-4 gap-3  grid-cols-1'>
        {agences.length > 0 ?

          agences.map(item => {
            return (
              <div className='relative flex justify-start border items-center bg-white dark:bg-neutral-800 w-full border-neutral-200 dark:border-neutral-700 px-8 py-6 rounded-xl'>

                {(checkAccess('agences', 'update') || checkAccess('agences', 'delete')) &&
                  <div className="absolute z-50 top-2 right-2 text-right">
                    <Menu>
                      <MenuButton className="inline-flex flex justify-center items-center  p-1 rounded w-7 h-7  items-center gap-2 dark:text-gray-200 text-gray-800 bg-neutral-100 dark:bg-neutral-900">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 12.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5ZM12 18.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5Z" />
                        </svg>
                      </MenuButton>
                      <Transition
                        enter="transition ease-out duration-75"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <MenuItems
                          anchor="bottom end"
                          className="w-52 origin-top-right rounded-xl border border-neutral-200 p-1  dark:border-neutral-700 bg-white dark:bg-neutral-900 p-1 text-sm/6 dark:text-gray-200 text-gray-800 [--anchor-gap:var(--spacing-1)] focus:outline-none"
                        >
                          {checkAccess('users', 'update') &&
                            <MenuItem>
                              <Link to={`/Agences/update/${item._id}`} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3  hover:bg-black/5 dark:hover:bg-white/5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                {t("Edit")}
                                <kbd className="ml-auto hidden font-sans text-xs dark:text-gray-200 text-gray-800 group-data-[focus]:inline">⌘E</kbd>
                              </Link>
                            </MenuItem>
                          }

                          {checkAccess('users', 'delete') &&
                            <MenuItem>
                              <button onClick={(e) => { setAgenceId(item._id); setDeleteModal(true) }} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3  hover:bg-black/5 dark:hover:bg-white/5">
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                {t("Delete")}
                                <kbd className="ml-auto hidden font-sans text-xs dark:text-gray-200 text-gray-800 text-white/50 group-data-[focus]:inline">⌘D</kbd>
                              </button>
                            </MenuItem>
                          }
                          <Link to={`/Agences/Machines/${item._id}/${item.name}`} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3  hover:bg-black/5 dark:hover:bg-white/5">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-eye"><path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" /><circle cx="12" cy="12" r="3" /></svg>
                            {t("Details")}
                            <kbd className="ml-auto hidden font-sans text-xs dark:text-gray-200 text-gray-800 group-data-[focus]:inline">⌘D</kbd>
                          </Link>
                        </MenuItems>
                      </Transition>
                    </Menu>
                  </div>
                }

                <div className='grid place-items-start gap-2'>

                  <div className='grid place-items-start gap-1'>
                    <h5 className="block  text-xl font-semibold leading-snug tracking-normal text-blue-neutral-900 dark:text-gray-300 ">
                      {item.name}
                    </h5>
                    <div className='flex flex-col text-[14px] mt-5 text-neutral-900 dark:text-neutral-200 justify-center items-start gap-2'>
                      <div className='flex justify-start items-center gap-3 '>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide text-neutral-800 dark:text-neutral-200 lucide-phone"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
                        {item.phone}
                      </div>
                      {item.zone?.name &&

                        <div className='flex justify-start items-center gap-3'>
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide text-neutral-800 dark:text-neutral-200 lucide-map-pinned"><path d="M18 8c0 4.5-6 9-6 9s-6-4.5-6-9a6 6 0 0 1 12 0" /><circle cx="12" cy="8" r="2" /><path d="M8.835 14H5a1 1 0 0 0-.9.7l-2 6c-.1.1-.1.2-.1.3 0 .6.4 1 1 1h18c.6 0 1-.4 1-1 0-.1 0-.2-.1-.3l-2-6a1 1 0 0 0-.9-.7h-3.835" /></svg>
                          {item.zone?.name}
                        </div>
                      }

                      {item.departement &&
                        <div className='flex justify-start items-center gap-3 '>
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide text-neutral-800 dark:text-neutral-200 lucide-locate-fixed"><line x1="2" x2="5" y1="12" y2="12" /><line x1="19" x2="22" y1="12" y2="12" /><line x1="12" x2="12" y1="2" y2="5" /><line x1="12" x2="12" y1="19" y2="22" /><circle cx="12" cy="12" r="7" /><circle cx="12" cy="12" r="3" /></svg>
                          {item.departement}
                        </div>
                      }
                      {item.code &&
                        <div className='flex justify-start items-center gap-3 '>
                          <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-signature"><path d="M14.218 7.183a2.5 2.5 0 1 0-3.712-2.354c-.349 2.295-.853 12.217-5.006 12.217a1 1 0 0 1 0-5.091c4.509.03 8.516 1.676 8.516 4.221a1 1 0 0 0 .781.803l2.429.015a1 1 0 0 0 1.006-1v-.4a.5.5 0 0 1 .838-.368L21 17" /><path d="M3 21h18" /></svg>
                          {item.code}
                        </div>
                      }
                      <div className='flex w-full mt-4  justify-center text-blue-600 dark:text-blue-400 items-center gap-3 '>
                        {checkAccess('generateur', 'read') &&

                          <div className='flex group transition-all duration-300 justify-center items-center rounded-md px-3 py-2 gap-2 bg-blue-50 dark:bg-blue-900/20 hover:bg-blue-100 dark:hover:bg-blue-800/30'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-air-vent">
                              <path d="M6 12H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
                              <path d="M6 8h12" />
                              <path d="M18.3 17.7a2.5 2.5 0 0 1-3.16 3.83 2.53 2.53 0 0 1-1.14-2V12" />
                              <path d="M6.6 15.6A2 2 0 1 0 10 17v-5" />
                            </svg>

                            <h1 className='overflow-hidden text-xs transition-all duration-400'>{t("Generateurs")}</h1>
                            <p>{item.generateur}</p>
                          </div>
                        }
                        {checkAccess('traitement_eau', 'read') &&
                          <div className='flex group transition-all duration-300 justify-center items-center rounded-md px-3 py-2 gap-2 bg-blue-50 dark:bg-blue-900/20 hover:bg-blue-100 dark:hover:bg-blue-800/30'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-lamp-ceiling"><path d="M12 2v5" /><path d="M6 7h12l4 9H2l4-9Z" /><path d="M9.17 16a3 3 0 1 0 5.66 0" /></svg>

                            <h1 className='overflow-hidden text-xs  transition-all duration-300'>{t("Traitement D'eau")}</h1>
                            <p>{item.traitementDeau}</p>
                          </div>
                        }
              
                      </div>
                    </div>


                  </div>

                </div>
              </div>
            )
          })


          :
          (
            <div className="min-h-60 md:col-span-5 flex flex-col bg-white border w-[100%] shadow-sm rounded-xl dark:bg-neutral-900 dark:border-neutral-700 dark:shadow-neutral-700/70">
              <div className="flex flex-auto flex-col justify-center items-center p-4 md:p-5">
                <svg className="size-10 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={1} strokeLinecap="round" strokeLinejoin="round">
                  <line x1={22} x2={2} y1={12} y2={12} />
                  <path d="M5.45 5.11 2 12v6a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-6l-3.45-6.89A2 2 0 0 0 16.76 4H7.24a2 2 0 0 0-1.79 1.11z" />
                  <line x1={6} x2="6.01" y1={16} y2={16} />
                  <line x1={10} x2="10.01" y1={16} y2={16} />
                </svg>
                <p className="mt-2 text-sm text-gray-800 dark:text-gray-300">
                  {t("No data to show")}
                </p>
              </div>
            </div>

          )
        }
      </div>

      <DeleteModal setDeleteId={setAgenceId} open={deleteModal} setOpen={setDeleteModal} DeleteId={agenceId} Model='agences' setDone={setDone} setLoding={setLoding} done={done} />


    </div>)
}

export default Agences