import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Input, Select } from '../../components';
import { DeleteModal } from '../../components';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';

function PreventifModalDetails({ preventif }) {
    const { t } = useTranslation();
    const [ConsomableData, setConsomableData] = useState({ article: null, quantity: 0, section: 'Preventif', section_id: preventif._id, prestation: preventif.type, agence: preventif.agence })
    const [Consomable, setConsomable] = useState([])

    const [CosomableId, setCosomableId] = useState(null)
    const [DeleteModalConsomable, setDeleteModalConsomable] = useState(false)


    const { checkAccess } = useAccessChecker();
    const [DetailsModal, setDetailsModal] = useState(null)
    const [articles, setArticles] = useState([])
    const [loading, setLoding] = useState(false)


    const [done, setDone] = useState(false)

    useEffect(() => {
        if (DetailsModal) {
            setConsomableData({ article: null, quantity: 0, section: 'Preventif', section_id: preventif._id, prestation: preventif.type, agence: preventif.agence })
            getConsomable(preventif._id)
            getArticles()
        }
    }, [preventif, done, DetailsModal])

    const handleChangeConsomable = async (e) => {
        setConsomableData({ ...ConsomableData, [e.target.name]: e.target.value });

    }

    const handleDetailsModal = async (id) => {
        if (id) {
            setDetailsModal(id)
            await getConsomable(id)
        } else {
            setDetailsModal(null)

        }

    }

    const handleEditConsomable = (item) => {
        setCosomableId(item._id)
        setConsomableData({ article: item.article, quantity: item.quantity, section: 'Preventif', section_id: preventif._id, prestation: preventif.type, agence: preventif.agence })

    }

    const saveConsomable = async (e) => {
        e.preventDefault();
        try {
            setLoding(true)
            let response = null
            if (CosomableId) {
                response = await axiosInstance.put(`/consomable/update/${CosomableId}`, ConsomableData);
            } else {
                response = await axiosInstance.post('/consomable/create', ConsomableData);
            }

            const responseData = response.data
            if (responseData && responseData.statusCode === 200) {
                toast.success(responseData.message);
                setDone(!done)
                setCosomableId(null)

            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message);
            setLoding(false)
        }
    };

    const getConsomable = async (id) => {
        try {
            setLoding(true)
            const response = await axiosInstance.get(`/consomable/searchRelated/Preventif/${id}`);
            const responseData = response.data

            if (responseData && responseData.statusCode === 200) {
                setConsomable(responseData.data)
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };



    const getArticles = async () => {
        try {
            setLoding(true)
            const response = await axiosInstance.get('/consomableSettings/search');
            const responseData = response.data

            if (responseData && responseData.statusCode === 200) {
                setArticles(responseData.data)
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };


    const checkColor = (status) => {
        switch (status) {
            case 'Non Traité':
                return 'dark:bg-red-600 dark:bg-opacity-10  bg-red-100 text-red-600 ';
            case 'En cours':
                return 'dark:bg-yellow-600 dark:bg-opacity-10  bg-yellow-100 text-yellow-600';
            case 'En cours de validation':
                return 'dark:bg-orange-600 dark:bg-opacity-10  bg-orange-100 text-orange-600';
            case 'Traité':
                return 'dark:bg-green-600 dark:bg-opacity-10  bg-green-100 text-green-600';
            default:
                return 'dark:bg-red-600 dark:bg-opacity-10  bg-red-100 text-red-600'; // Replace with your actual default color class or value
        }
    };

    const formatDate = (date) => {
        if (!date) return '';

        const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit' };

        const formattedDate = new Date(date).toLocaleDateString('en-US', dateOptions);
        const formattedTime = new Date(date).toLocaleTimeString('en-US', timeOptions);

        return `${formattedDate}`;
    };

    const RetardCalcul = (date) => {
        const today = new Date();
        const inputDate = new Date(date);
        const differenceInTime = today - inputDate;
        const differenceInDays = Math.floor(differenceInTime / (1000 * 60 * 60 * 24));

        const remainingHours = Math.floor((differenceInTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

        return `${differenceInDays} day(s) and ${remainingHours} hour(s)`;
    }


    return (
        <div className="flex flex-col  justify-start   ">

            <a onClick={() => handleDetailsModal(preventif._id)} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-yellow-600 dark:bg-opacity-10 rounded-full bg-yellow-100 text-yellow-600'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-eye"><path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" /><circle cx="12" cy="12" r="3" /></svg>
            </a>



            <AnimatePresence>

                {DetailsModal &&
                    <div
                        className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                        <div className="absolute bg-black opacity-80 inset-0 z-0" />
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.3 }}
                            className="w-screen md:w-[80%] py-4 md:h-auto h-[98%] overflow-y-auto relative mx-auto mx-2  rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
                            <div className="flex  flex-col justify-center items-center w-full px-6 py-3">
                                <div className="flex w-full justify-between items-center gap-x-3 mb-6">
                                    <div className='flex justify-start items-center gap-4'>
                                        <h2 className="text-[20px] font-[700] text-gray-800 dark:text-white"> {t("Preventif Details")}</h2>

                                        <div className={`flex justify-center items-center w-fit text-[13px] px-6 gap-1 ${checkColor(preventif.status)}  rounded-full  py-2`}>
                                            {t(preventif.status)}
                                        </div>
                                    </div>

                                    <a onClick={() => handleDetailsModal()} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>
                                </div>
                                <div className='grid  w-full place-items-center gap-3  md:p-3'>
                                    <div className='grid place-items-start md:grid-cols-2 w-full gap-3 border border-gray-200 dark:border-neutral-700 rounded-lg p-5'>

                                        <div className='flex justify-start items-center gap-3'>
                                            <h1 className='text-[16px] font-[600] text-neutral-700 dark:text-neutral-400'>{t("Code Agence")} :</h1>
                                            <p className='text-[16px] font-[400] text-neutral-900 dark:text-neutral-100'>{preventif.agence?.code}</p>
                                        </div>
                                        <div className='flex justify-start items-center gap-3'>
                                            <h1 className='text-[16px] font-[600] text-neutral-700 dark:text-neutral-400'>{t("Agence")} :</h1>
                                            <p className='text-[16px] font-[400] text-neutral-900 dark:text-neutral-100'>{preventif.agence?.name}</p>
                                        </div>
                                        <div className='flex justify-start items-center gap-3'>
                                            <h1 className='text-[16px] font-[600] text-neutral-700 dark:text-neutral-400'>{t("Trimestre")} :</h1>
                                            <p className='text-[16px] font-[400] text-neutral-900 dark:text-neutral-100'>{preventif.trimestre}</p>
                                        </div>
                                        <div className='flex justify-start items-center gap-3'>
                                            <h1 className='text-[16px] font-[600] text-neutral-700 dark:text-neutral-400'>{t("Date")} :</h1>
                                            <p className='text-[16px] font-[400] text-neutral-900 dark:text-neutral-100'>{formatDate(preventif.createdAt)}</p>
                                        </div>


                                        <div className='flex justify-start items-center gap-3'>
                                            <h1 className='text-[16px] font-[600] text-neutral-700 dark:text-neutral-400'>{t("Manager")} :</h1>
                                            <p className='text-[16px] font-[400] text-neutral-900 dark:text-neutral-100'>{preventif.manager ? preventif.manager?.fullName : 'None'}</p>
                                        </div>
                                        <div className='flex justify-start items-center gap-3'>
                                            <h1 className='text-[16px] font-[600] text-neutral-700 dark:text-neutral-400'>{t("Technicien")} :</h1>
                                            <p className='text-[16px] font-[400] text-neutral-900 dark:text-neutral-100'>{preventif.technicien ? preventif.technicien?.fullName : 'None'}</p>
                                        </div>
                                        <div className='flex justify-start items-center gap-3'>
                                            <h1 className='text-[16px] font-[600] text-neutral-700 dark:text-neutral-400'>{t("Comment")} :</h1>
                                            <p className='text-[16px] font-[400] text-neutral-900 dark:text-neutral-100'>{preventif.comment ? preventif.comment : 'None'}</p>
                                        </div>
                                        <div className='flex justify-start items-center gap-3'>
                                            <h1 className='text-[16px] font-[600] text-neutral-700 dark:text-neutral-400'>{t("Bon d'intervention")} :</h1>
                                            {preventif.facture ?
                                                <a href={preventif.facture} target='_blank' className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-file-down"><path d="M15 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7Z" /><path d="M14 2v4a2 2 0 0 0 2 2h4" /><path d="M12 18v-6" /><path d="m9 15 3 3 3-3" /></svg>                                                                </a>

                                                :
                                                <p className='text-[16px] font-[400] text-neutral-900 dark:text-neutral-100'>None</p>

                                            }
                                        </div>
                                        {preventif.status === 'Traité' &&
                                            <div className='flex justify-start items-center gap-3'>
                                                <h1 className='text-[16px] font-[600] text-neutral-700 dark:text-neutral-400'>{t("Date Résolution")} :</h1>
                                                <p className='text-[16px] font-[400] text-neutral-900 dark:text-neutral-100'>{formatDate(preventif.updatedAt)}</p>
                                            </div>
                                        }
                                        <div className='flex justify-start items-center gap-3'>
                                            <h1 className='text-[16px] font-[600] text-neutral-700 dark:text-neutral-400'>{t("Retard")} :</h1>
                                            <p className='text-[16px] font-[400] text-neutral-900 dark:text-neutral-100'>{RetardCalcul(preventif.createdAt)}</p>
                                        </div>

                                    </div>
                                    {checkAccess('consomable', 'read') &&

                                        <div className="flex w-full justify-between items-center gap-x-3 mb-6 mt-3 -ml-5 ">
                                            <h2 className="text-[20px] font-[700] text-gray-800 dark:text-white"> {t("Consomable Details")}</h2>
                                        </div>
                                    }

                                    {checkAccess('consomable', 'read') &&


                                        <div className='grid place-items-start  w-full gap-3 border border-gray-200 dark:border-neutral-700 rounded-lg p-5'>
                                            {checkAccess('consomable', 'create') &&
                                                <div className='w-full flex items-center gap-3 justify-center md:flex-row flex-col '>
                                                    <Select label={t("Article")} options={articles} name="article" handleChange={handleChangeConsomable} defaultV={ConsomableData.article || null} />
                                                    <Input type='number' label={t("Quantity")} name="quantity" handleChange={handleChangeConsomable} defaultV={ConsomableData.quantity || 0} />


                                                    <div className="flex items-center w-full ">
                                                        <button onClick={(e) => saveConsomable(e, ConsomableData._id)} className="flex items-center justify-center h-[3rem] w-full px-6 py-3 font-[600]  text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0  gap-x-2  dark:bg-neutral-100">
                                                            {
                                                                !loading ?
                                                                    (
                                                                        <>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                                            </svg>

                                                                            <span>{CosomableId ? t("Update comsomable") : t("Save comsomable")}</span>
                                                                        </>
                                                                    )
                                                                    :

                                                                    (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                                                        <span className="sr-only">Loading...</span>
                                                                    </div>)

                                                            }

                                                        </button>
                                                    </div>
                                                </div>
                                            }



                                            <div class="flex flex-col w-full py-2">
                                                <div class="-m-1.5 overflow-x-auto">
                                                    <div class="p-1.5 min-w-full inline-block align-middle">
                                                        <div class="border rounded-lg max-h-64 overflow-y-auto dark:border-neutral-700">
                                                            <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                                                                <thead class="bg-gray-50 dark:bg-neutral-900 ">
                                                                    <tr className='h-14'>
                                                                        <th scope="col" class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">{t("Article")}</th>
                                                                        <th scope="col" class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">{t("Marque")}</th>
                                                                        <th scope="col" class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">{t("Qauntity")}</th>
                                                                        <th scope="col" class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">{t("Date")}</th>
                                                                        <th scope="col" class="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-400">{t("Actions")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody class="divide-y divide-gray-200 dark:divide-neutral-700 ">



                                                                    {Consomable.length > 0 ?
                                                                        Consomable.map(item => {
                                                                            return (
                                                                                <tr key={item} className='h-14'>
                                                                                    <td class="px-6 py-4 text-start whitespace-nowrap text-sm font-medium text-gray-800 dark:text-neutral-200">{item.article.name}</td>
                                                                                    <td class="px-6 py-4 text-start whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">{item.article.marque}</td>
                                                                                    <td class="px-6 py-4 text-start whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">{item.quantity}</td>
                                                                                    <td class="px-6 py-4 text-start whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                                                                                        <div className={`flex justify-center items-center w-fit text-[13px] px-6 gap-1 ${checkColor('Traité')}  rounded-full  py-2`}>
                                                                                            {formatDate(item.createdAt)}
                                                                                        </div></td>

                                                                                    <td class="px-6 py-4 text-start  whitespace-nowrap flex justify-start items-center text-sm font-medium">
                                                                                        {checkAccess('consomable', 'delete') &&
                                                                                            <a onClick={() => { setCosomableId(item._id); setDeleteModalConsomable(true) }} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-red-600 dark:bg-opacity-10 rounded-full bg-red-100 text-red-600'>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                                                                            </a>
                                                                                        }
                                                                                        {checkAccess('consomable', 'update') &&
                                                                                            <a onClick={() => handleEditConsomable(item)} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-100 text-green-600'>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                                                                            </a>

                                                                                        } </td>
                                                                                </tr>
                                                                            )
                                                                        })
                                                                        :
                                                                        <tr className="w-full">
                                                                            <td colSpan="10" className="px-6 py-14 animate-pulse  text-gray-500 dark:text-gray-500 text-3xl text-center font-medium">
                                                                                {t("There is no data to be displayed !!!!")}
                                                                            </td>
                                                                        </tr>

                                                                    }


                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                    }




                                </div>



                            </div>
                        </motion.div>
                    </div>
                }
            </AnimatePresence >


            <DeleteModal setDeleteId={setCosomableId} open={DeleteModalConsomable} setOpen={setDeleteModalConsomable} DeleteId={CosomableId} Model='consomable' setDone={setDone} setLoding={setLoding} done={done} />

        </div>
    )
}

export default PreventifModalDetails