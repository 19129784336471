import React, { useEffect, useState } from 'react'
import Profile from './Profile';
import Zones from './Zones';
import Prestation from './Prestation';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';

function Settings() {
  const { t } = useTranslation();


  const { checkAccess } = useAccessChecker();

  const [activeTab, setActiveTab] = useState('Tab1');

  useEffect(() => {
    const tab = localStorage.getItem('tabSettings')
    if (tab) {
      setActiveTab(tab)

    }

  }, [])

  const handleTabClick = (tab) => {
    localStorage.setItem('tabSettings', tab)
    setActiveTab(tab);
  };

  return (
    <div className="flex flex-col w-[100%] justify-start p-6  h-[100vh]">
      <ul className="flex md:flex-row flex-col justify-start items-center gap-2">
        {checkAccess('profile', 'read') &&

          <li
            className={` cursor-pointer rounded-full flex w-fit justify-center items-center gap-1 py-2 px-4 text-sm font-semibold  ${activeTab === 'Tab1' ? 'text-blue-600 bg-blue-400/15 dark:bg-blue-900/20 dark:text-blue-400 hover:bg-gray-400/10 dark:hover:bg-blue-900/10' : 'text-gray-800 shadow dark:text-white bg-gray-50 dark:bg-white/5 hover:bg-gray-100 dark:hover:bg-white/10'} focus:outline-none `}
            onClick={() => handleTabClick('Tab1')}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-user-cog"><circle cx="18" cy="15" r="3" /><circle cx="9" cy="7" r="4" /><path d="M10 15H6a4 4 0 0 0-4 4v2" /><path d="m21.7 16.4-.9-.3" /><path d="m15.2 13.9-.9-.3" /><path d="m16.6 18.7.3-.9" /><path d="m19.1 12.2.3-.9" /><path d="m19.6 18.7-.4-1" /><path d="m16.8 12.3-.4-1" /><path d="m14.3 16.6 1-.4" /><path d="m20.7 13.8 1-.4" /></svg>
            {t("Profile")}
          </li>
        }
        {checkAccess('zones', 'read') &&

          <li
            className={` cursor-pointer rounded-full flex w-fit justify-center items-center gap-1 py-2 px-4 text-sm font-semibold  ${activeTab === 'Tab2' ? 'text-blue-600 bg-blue-400/15 dark:bg-blue-900/20 dark:text-blue-400 hover:bg-gray-400/10 dark:hover:bg-blue-900/10' : 'text-gray-800 shadow dark:text-white bg-gray-50 dark:bg-white/5 hover:bg-gray-100 dark:hover:bg-white/10'} focus:outline-none `}
            onClick={() => handleTabClick('Tab2')}
          >
            {t("Zone / Departement")}
          </li>
        }
        {checkAccess('prestation', 'read') &&

          <li
            className={` cursor-pointer rounded-full flex w-fit justify-center items-center gap-1 py-2 px-4 text-sm font-semibold  ${activeTab === 'Tab3' ? 'text-blue-600 bg-blue-400/15 dark:bg-blue-900/20 dark:text-blue-400 hover:bg-gray-400/10 dark:hover:bg-blue-900/10' : 'text-gray-800 shadow dark:text-white bg-gray-50 dark:bg-white/5 hover:bg-gray-100 dark:hover:bg-white/10'} focus:outline-none `}
            onClick={() => handleTabClick('Tab3')}
          >
            {t("Natues D'intervention")}
          </li>
        }
   
      </ul>
      <div className="tab-content">
        {activeTab === 'Tab1' && <div><Profile /></div>}
        {activeTab === 'Tab2' && <div><Zones /></div>}
        {activeTab === 'Tab3' && <div><Prestation /></div>}

      </div>
    </div>)
}

export default Settings