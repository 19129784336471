import { Routes, Route } from 'react-router-dom';
import { Login, Dashboard, Users, Agences, Correctives, Preventives, NotFoundPage, Roles, FormRoles, FormUsers, Settings, FormAgences, Machines, ConsomableGlobal, Supplier, ConsomableHistory } from '../pages';
import { MainLayout } from '../layout';
import ProtectedRoute from './ProtectedRoute';
import PublicRoute from './PublicRoute';



const Routers = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Dashboard />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        {/* **************************************Roles*Start******************************************* */}
        <Route
          path="/Roles"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Roles />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Roles/New"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormRoles />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Roles/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormRoles />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        {/* **************************************Roles*End******************************************* */}

        {/* **************************************Users*Start******************************************* */}

        <Route
          path="/Techniciens"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Users />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Techniciens/New"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormUsers />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Techniciens/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormUsers />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* **************************************Users*End******************************************* */}
        {/* **************************************Agences*Start******************************************* */}

        <Route
          path="/Agences"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Agences />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Agences/New"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormAgences />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Agences/update/:id"
          element={
            <ProtectedRoute>
              <MainLayout>
                <FormAgences />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Agences/Machines/:id/:name"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Machines />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        {/* **************************************Agences*End******************************************* */}

        <Route
          path="/Correctives/:id?"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Correctives />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Preventives/:id?"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Preventives />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/ConsomableGlobal"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ConsomableGlobal />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/ConsomableHistory/:id/:name/:reference"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ConsomableHistory />
              </MainLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/Suppliers"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Supplier />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/Settings"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Settings />
              </MainLayout>
            </ProtectedRoute>
          }
        />
        <Route path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          } />
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
};

export default Routers;
