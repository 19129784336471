import React, { useEffect, useState } from 'react'
import { Input, Loading } from '../../components'
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';




function Profile() {
    const { t } = useTranslation();

    const [done, setDone] = useState(false)
    const [loading, setLoding] = useState(false)

    const [formData, setFormData] = useState({ doubleAuth: false, email: null, fullName: null, phone: null, image: null })
    const [passwordChange, setPasswordChange] = useState({ current: null, newPass: null, confirmNewPass: null })


    useEffect(() => {
        getProfileToUpdate()
    }, [done])

    const handlePasswordChange = (e) => {
        setPasswordChange({ ...passwordChange, [e.target.name]: e.target.value });
    }

    const handleChange = (e) => {
        if (e.target.type === 'file') {
            const file = e.target.files[0];
            if (file) {
                if (file.size > 15 * 1024 * 1024) {
                    toast.warning('File size exceeds 15 MB limit');
                    e.target.value = '';
                    return;
                }
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    setFormData({ ...formData, image: reader.result });
                };
            }
        } else {
            const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
            setFormData({ ...formData, [e.target.name]: value });
        }
    };

    const getProfileToUpdate = async () => {
        try {
            setLoding(true)
            const response = await axiosInstance.get(`/users/profile`);
            const responseData = response.data
            if (responseData && responseData.statusCode === 200) {
                setFormData(responseData.data.user)
            }
            setLoding(false)
        } catch (error) {
            setLoding(false)
            toast.error(error.response.data.message);
            console.error('Role creation failed:', error.response.data);
        }
    }




    const save = async (e) => {
        e.preventDefault();
        try {
            setLoding(true)
            let response = null
            response = await axiosInstance.put(`/users/updateProfile`, formData);

            const responseData = response.data
            if (responseData && responseData.statusCode === 200) {
                setDone(!done)
                toast.success(responseData.message);

            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message);
            setLoding(false)
            console.error('User creation failed:', error.response.data);
        }
    };

    const changePassword = async (e) => {
        e.preventDefault();
        try {
            setLoding(true)
            let response = null
            response = await axiosInstance.put(`/users/changePassword`, passwordChange);

            const responseData = response.data
            if (responseData && responseData.statusCode === 200) {
                setDone(!done)
                toast.success(responseData.message);

            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message);
            setLoding(false)
            console.error('User creation failed:', error.response.data);
        }
    };






    return (

        <div className='relative w-full h-full'>



            {loading &&
                <Loading />
            }

            <div className='grid  py-8  '>


                <div className='w-full flex md:flex-row gap-5 flex-col '>
                    <div className='md:w-1/4 w-full grid gap-3 p-6 border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800 rounded-2xl'>
                        <div className="flex items-center justify-center  border-2 w-44 h-44 mx-auto  rounded-full p-2 border-neutral-200 border-dashed dark:border-neutral-700">
                            <label htmlFor="dropzone-file" className="flex flex-col items-center justify-center w-full h-full rounded-full cursor-pointer bg-neutral-50 dark:bg-neutral-900 hover:bg-neutral-100">
                                {formData.image ? (
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <img src={formData.image} alt="Uploaded" className='rounded-full m-1 w-44 h-40' />
                                    </div>
                                ) : (
                                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-camera w-8 h-8 mb-4 text-gray-500 dark:text-gray-400"><path d="M14.5 4h-5L7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2h-3l-2.5-3z" /><circle cx={12} cy={13} r={3} /></svg>
                                        <p className="mb-2 text-xs text-gray-500 dark:text-gray-400 text-semibold">{t("Upload photo")}</p>
                                    </div>
                                )}

                                <input onChange={(e) => handleChange(e)} id="dropzone-file" type="file" name="image" className="hidden" />
                            </label>

                        </div>
                        {formData.image &&
                            <a onClick={(e) => setFormData({ ...formData, image: null })} className='flex cursor-pointer mb-2 justify-start items-center gap-2 px-2  rounded-md border text-[12px] border-neutral-200 bg-neutral-50 w-fit mx-auto dark:bg-neutral-900 dark:border-neutral-700 text-gray-800 dark:text-gray-100'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                {t("Image")}

                            </a>
                        }


                        <div className='text-center text-xs text-gray-500 dark:text-gray-400 text-[500] '>
                            Allowed *.jpeg, *.jpg, *.png, *.gif <br />
                            <span>max size of 3 Mb</span>
                        </div>

                        <div className='flex justify-start items-center text-gray-800 dark:text-gray-100 gap-2 border border-neutral-200 dark:border-neutral-700 rounded-2xl p-3'>
                            <div className='grid place-items-start gap-3 '>
                                <div className='flex w-full justify-between items-center '>
                                    <div className='flex justify-center items-center  gap-2'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-shield-check"><path d="M20 13c0 5-3.5 7.5-7.66 8.95a1 1 0 0 1-.67-.01C7.5 20.5 4 18 4 13V6a1 1 0 0 1 1-1c2 0 4.5-1.2 6.24-2.72a1.17 1.17 0 0 1 1.52 0C14.51 3.81 17 5 19 5a1 1 0 0 1 1 1z" /><path d="m9 12 2 2 4-4" /></svg>
                                        <h5 className='text-[16px] font-[600] capitalize text-start'>2FA </h5>
                                    </div>
                                    <label className="inline-flex items-center cursor-pointer">
                                        <input type="checkbox" onChange={(e) => handleChange(e)} checked={formData.doubleAuth} name='doubleAuth' className="sr-only peer" />
                                        <div className="relative w-11 h-6 bg-neutral-200 rounded-full peer dark:bg-neutral-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[2px] after:bg-white after:border-neutral-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-neutral-600 peer-checked:bg-blue-600" />
                                    </label>
                                </div>
                                <p className='text-[12px] font-[400] capitalize text-start'>{t("Two-factor authentication via email sends a login verification code to the user's email.")}</p>
                            </div>
                        </div>

                    </div>
                    <div className='md:w-3/4 relative  w-full grid gap-3 p-6 border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800 rounded-2xl'>
                        <form action='' className='w-full '>

                            <div className='grid md:grid-cols-2 grid-cols-1 w-full place-items-center gap-6 md:p-5 p-3'>

                                <Input type='text' disabled={false} label="Full Name" name="fullName" handleChange={handleChange} defaultV={formData.fullName || null} />

                                <Input type='text' disabled={false} label="Phone Number" name="phone" handleChange={handleChange} defaultV={formData.phone || null} />

                                <Input type='email' disabled={false} label="Email" name="email" handleChange={handleChange} defaultV={formData.email || null} />

                                <Input type='text' disabled={true} label="Role" name="role" handleChange={handleChange} defaultV={formData.role?.name || null} />


                                {/* <Select label="Zone" options={null} name="zone" handleChange={handleChange} defaultV={formData.zone || null} /> */}

                                <Input type='text' disabled={true} label="Etat" name="etat" handleChange={handleChange} defaultV={formData.etat || null} />

                                <Input type='text' disabled={true} label="Fontion" name="fonction" handleChange={handleChange} defaultV={formData.fonction || null} />


                                <div className='md:col-span-2 w-full flex items-center justify-end'>
                                    <div className="flex items-center mt-4 gap-x-3">
                                        <button onClick={(e) => save(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <span>{t("Update Profile")}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className=' w-full grid gap-3 p-6 border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800 mt-6 rounded-2xl'>
                    <form action='' className='w-full '>
                        <div className="flex items-center justify-start w-full mb-2 px-6">
                            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Password settings")} </h2>
                        </div>
                        <div className='grid md:grid-cols-3 grid-cols-1 w-full place-items-center gap-6  md:p-5 p-3 '>

                            <Input type='password' disabled={false} label="Current password" name="current" handleChange={handlePasswordChange} defaultV={null} />

                            <Input type='password' disabled={false} label="New password" name="newPass" handleChange={handlePasswordChange} defaultV={null} />

                            <Input type='password' disabled={false} label="Confirm New password" name="confirmNewPass" handleChange={handlePasswordChange} defaultV={null} />


                            <div className='md:col-span-3 w-full flex items-center justify-end '>
                                <div className="flex items-center mt-4 gap-x-3">
                                    <button onClick={(e) => changePassword(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                        </svg>
                                        <span>{t("Change Password")}</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

        </div>

    )
}

export default Profile