import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Input } from '../../components';
import { DeleteModal } from '../../components';
import ReactPaginate from 'react-paginate';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';


function Zones() {
    const { t } = useTranslation();

    const { checkAccess } = useAccessChecker();

    const [ZoneModal, setZoneModal] = useState(false)
    const [ZoneId, setZoneId] = useState(null)
    const [departement, setDepartement] = useState(null)

    const [formData, setFormData] = useState({ name: false, description: null, departements: [] })
    const [loading, setLoding] = useState(false)
    const [Zones, setZones] = useState([])
    const [done, setDone] = useState(false)
    const [searchValue, setSearchValue] = useState(null)


    const [deleteModal, setDeleteModal] = useState(false)


    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // change this as needed
    const pageCount = Math.ceil(Zones.length / itemsPerPage);
    const displayedItems = Zones.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );


    useEffect(() => {
        getZones()
    }, [ZoneId, done])

    const getZones = async () => {
        try {
            setLoding(true)
            const response = await axiosInstance.get('/zones/search');
            const responseData = response.data

            if (responseData && responseData.statusCode === 200) {
                setZones(responseData.data)
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };


    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }
    const handleChangeDepartments = (e) => {
        setDepartement(e.target.value)
    }
    const handleDeparetments = (e) => {
        e.preventDefault();
        setFormData({ ...formData, departements: [...formData.departements, departement] });
        setDepartement('');
    };

    const deleteDepartement = (item) => {
        const updatedDepartments = formData.departements.filter(department => department !== item);
        setFormData({ ...formData, departements: updatedDepartments });
    };



    const handleToggleModal = (id) => {
        if (id) {
            setZoneId(id)
            getZoneToUpdate(id)
        } else {
            setZoneModal(!ZoneModal)
        }
    }

    const getZoneToUpdate = async (id) => {
        try {
            setLoding(true)
            const response = await axiosInstance.get(`/zones/get/${id}`);
            const responseData = response.data
            if (responseData && responseData.statusCode === 200) {
                setFormData(responseData.data)
                setZoneModal(!ZoneModal)

            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    }

    const reset = () => {
        setSearchValue('')
        getZones()
    }
    const find = async (e) => {
        try {
            e.preventDefault()
            setLoding(true)
            const response = await axiosInstance.post(`/zones/find`, { searchValue });
            const responseData = response.data

            if (responseData && responseData.statusCode === 200) {
                setZones(responseData.data)
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };


    const save = async (e) => {
        e.preventDefault();
        try {
            setLoding(true)
            let response = null
            if (ZoneId) {
                response = await axiosInstance.put(`/zones/update/${ZoneId}`, formData);
            } else {
                response = await axiosInstance.post('/zones/create', formData);
            }
            const responseData = response.data
            if (responseData && responseData.statusCode === 200) {
                if (ZoneId) {
                    toast.success(responseData.message);
                    setZoneId(null)
                    setZoneModal(false)
                    setDone(!done)
                } else {
                    toast.success(responseData.message);
                    handleToggleModal()
                    setDone(!done)
                }
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message);
            setLoding(false)
        }
    };


    return (
        <div className="flex flex-col w-[100%] justify-start py-8  h-[100vh]">
            <div className="sm:flex sm:items-center mb-6 sm:justify-between">
                <div className='flex flex-col justify-center items-start'>
                    <div className="flex items-center gap-x-3">
                        <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Zones")}</h2>
                        <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-neutral-800 dark:text-blue-400 lowercase">{Zones.length} {t("Zones")}</span>
                    </div>
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-300"> {t("Zones description")} </p>
                </div>
                <div className="flex items-center mt-4 gap-x-3">
                    {checkAccess('zones', 'create') &&

                        <button onClick={() => { handleToggleModal() }} className="flex items-center justify-center w-1/2 px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                            <span>{t("Add Zone")}</span>
                        </button>
                    }
                </div>
            </div>
            <div className="flex flex-col max-w-full ">
                <div className="-m-1.5 ">
                    <div className=" w-full  inline-block align-middle">
                        <div className="border rounded-lg divide-y overflow-x-auto max-w-full  bg-white dark:bg-neutral-800 divide-neutral-200 dark:border-neutral-700 dark:divide-neutral-700">
                            <div className="py-3 px-4  ">
                                <div className="relative md:w-1/2 w-full">
                                    <label htmlFor="hs-table-search" className="sr-only">{t("Search")}</label>
                                    <input type="search" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} name="hs-table-search" id="hs-table-search" className="py-2 cursor-pointer px-3 ps-9 block w-full border border-neutral-200 bg-neutral-50 h-14 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" placeholder={t("Search for items")} />
                                    <a onClick={(e) => reset(e)} className="absolute top-4 left-2 z-50 flex items-center ">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 mr-2 z-45  text-gray-800 dark:text-neutral-100">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>


                                    </a>
                                    <button onClick={(e) => find(e)} disabled={!searchValue} className="block h-10 absolute top-2 right-2 flex items-center  justify-center  md:px-6 px-3 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg   dark:bg-neutral-100 lowercase">{t("Search")}</button>

                                </div>
                            </div>
                            <div className="overflow-x-auto max-w-full">
                                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700">
                                    <thead className=" bg-neutral-50 dark:bg-neutral-900 h-14">
                                        <tr>

                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Name")}</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Description")}</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Departements")}</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Actions")}</th>

                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-neutral-200 dark:divide-neutral-700">
                                        {displayedItems.length > 0 ?
                                            displayedItems.map(item => {
                                                return (
                                                    <tr key={item}>

                                                        <td className="px-6 py-4 whitespace-nowrap text-start text-sm font-medium text-gray-800 dark:text-gray-200">{item.name}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.description}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.departements.length}</td>

                                                        <td className="px-6 py-4 whitespace-nowrap text-start text-end text-sm font-medium">
                                                            <div className='flex justify-start items-center gap-2'>
                                                                {checkAccess('zones', 'delete') &&

                                                                    <a onClick={() => { setZoneId(item._id); setDeleteModal(true) }} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-red-600 dark:bg-opacity-10 rounded-full bg-red-100 text-red-600'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                                                    </a>
                                                                }
                                                                {checkAccess('zones', 'create') &&

                                                                    <a onClick={() => handleToggleModal(item._id)} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-100 text-green-600'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                                                    </a>
                                                                }
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr className="w-full">
                                                <td colSpan="10" className="px-6 py-14 animate-pulse  text-gray-500 dark:text-gray-500 text-3xl text-center font-medium">
                                                    {t("There is no data to be displayed !!!!")}
                                                </td>
                                            </tr>

                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="relative my-6">
                        {displayedItems.length > 0 &&

                            <ReactPaginate
                                pageCount={pageCount}
                                onPageChange={({ selected }) => setCurrentPage(selected)}
                                containerClassName=" w-full flex justify-center  items-center "
                                pageClassName="border dark:border-neutral-700 flex justify-center items-center ml-2 w-8  h-8 rounded-full"
                                activeClassName="bg-green-500 text-white"
                                previousClassName="bg-white dark:bg-neutral-900 dark:text-white border flex justify-center items-center text-neutral-800 hover:text-green-500 border-gray-200 dark:border-neutral-700 absolute left-0 px-6 py-2 rounded-full"
                                nextClassName="bg-white dark:bg-neutral-900 dark:text-white border flex justify-center items-center text-neutral-800 hover:text-green-500 border-gray-200 dark:border-neutral-700 absolute right-0   px-6 py-2 rounded-full"
                                disabledClassName="opacity-50 cursor-not-allowed"
                                breakClassName="mx-2"
                                pageLinkClassName="hover:text-green-500"
                                previousLinkClassName="hover:text-green-500"
                                nextLinkClassName="hover:text-green-500"
                                breakLinkClassName="hover:text-green-500"
                            />
                        }
                    </div>
                </div>
            </div>


            <AnimatePresence>

                {ZoneModal &&
                    <div
                        className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
                        <div className="absolute bg-black opacity-80 inset-0 z-0" />
                        <motion.div
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.3 }}
                            className="max-w-full md:w-[30%] py-4 relative mx-auto my-auto rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
                            <div className="flex  flex-col justify-center items-center w-full px-6 py-3">
                                <div className="flex w-full justify-between items-center gap-x-3 mb-6">
                                    <h2 className="text-[20px] font-[700] text-gray-800 dark:text-white">{t("Add Zone")}</h2>
                                    <a onClick={() => handleToggleModal()} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>
                                </div>
                                <div className='grid w-full place-items-center gap-3 '>
                                    <Input type='text' disabled={false} label="Name" name="name" handleChange={handleChange} defaultV={formData.name || null} />
                                    <Input type='text' disabled={false} label="Description" name="description" handleChange={handleChange} defaultV={formData.description || null} />

                                    <div className='w-full flex justify-center items-center gap-2 '>
                                        <Input type='text' disabled={false} label="Departements" name="Departements" handleChange={handleChangeDepartments} defaultV={departement} />

                                        <button onClick={(e) => handleDeparetments(e)} className='flex justify-center items-center h-[3.3rem] w-14 dark:text-gray-100 text-neutral-800 border border-gray-200 dark:border-neutral-700  rounded-md bg-gray-50 dark:bg-neutral-900'>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-plus"><path d="M5 12h14" /><path d="M12 5v14" /></svg>
                                        </button>
                                    </div>
                                    <div className='w-full flex justify-center items-center gap-2 px-2 py-2 dark:text-gray-100 text-neutral-800 border border-gray-200 dark:border-neutral-700  rounded-md bg-gray-50 dark:bg-neutral-900'>
                                        {
                                            formData.departements.length > 0 ?
                                                (
                                                    <div className='flex flex-1 justify-start items-center gap-2'>
                                                        {
                                                            formData.departements.map(item => {
                                                                return (
                                                                    <div className='cursor-pointer rounded-full flex w-fit justify-center items-center gap-1 py-2 px-4 text-sm font-semibold  text-gray-800 shadow dark:text-white bg-gray-50 dark:bg-white/5 hover:bg-gray-100 dark:hover:bg-white/10 focus:outline-none ' >
                                                                        {item}
                                                                        <a onClick={(e) => deleteDepartement(item)}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>

                                                                        </a>

                                                                    </div>
                                                                )
                                                            })

                                                        }
                                                    </div>
                                                )
                                                :
                                                (
                                                    <div>
                                                        <p className="text-[20px] font-[600] text-gray-800/70 animate-pulse infinite dark:text-white/70">{t("There is no departements yet ...")}</p>

                                                    </div>
                                                )
                                        }
                                    </div>


                                </div>

                                <div className='md:col-span-3 w-full flex items-center justify-end '>
                                    <div className="flex items-center mt-6 gap-x-3">
                                        <button onClick={(e) => save(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                                            {
                                                !loading ?
                                                    (
                                                        <>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                            </svg>
                                                            <span>{t("Save zone")}</span>
                                                        </>
                                                    )
                                                    :

                                                    (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>)

                                            }

                                        </button>
                                    </div>
                                </div>

                            </div>
                        </motion.div>
                    </div>
                }
            </AnimatePresence >


            <DeleteModal setDeleteId={setZoneId} open={deleteModal} setOpen={setDeleteModal} DeleteId={ZoneId} Model='zones' setDone={setDone} setLoding={setLoding} done={done} />

        </div >


    )
}

export default Zones