import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { useTheme } from '../../utils/context/ThemeContext';
import { useTranslation } from 'react-i18next';

const CorrectivesPerQuarter = ({ data = [] }) => { // Default to empty array
    const { t } = useTranslation();
    const chartRef = useRef(null);
    const { theme } = useTheme();

    useEffect(() => {
        const chartDom = chartRef.current;
        const myChart = echarts.init(chartDom);
     const monthNames = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
];

        const textColor = theme === 'dark' ? '#ffffff' : '#000000'; // Adjust text color based on theme

        // Format the data for the chart
        const months = data.map(d => monthNames[d.month - 1]);
        const counts = data.map(d => d.count);

        const option = {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            xAxis: {
                type: 'category',
                data: months,
                axisLabel: {
                    color: textColor
                }
            },
                itemStyle: {
    borderRadius: [10, 10, 0, 0] 
},
            yAxis: {
                type: 'value',
                axisLabel: {
                    color: textColor
                }
            },
            series: [
                {
                    name: t('Correctifs'), // Label for the series
                    type: 'bar',
                    data: counts,
                    label: {
                        show: true,
                        position: 'top',
                        color: textColor
                    },
                    emphasis: {
                        focus: 'series'
                    }
                }
            ],
                 color:["#115f9a",  "#22a7f0","#76c68f",  "#c9e52f", "#d0ee11", "#d0f400"]

        };

        myChart.setOption(option);

        // Handle window resize
        const handleResize = () => {
            myChart.resize();
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            myChart.dispose();
        };
    }, [theme, data]);

    return <div ref={chartRef} style={{ width: '100%', height: '550px' }}></div>;
};

export default CorrectivesPerQuarter;
