import React, { useEffect, useState } from 'react'
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';
import Generateur from './Generateur';
import TraitementDeau from './TraitementDeau';


function Settings() {
    const { t } = useTranslation();

    const { currentUser } = useCurrentUser();
    const { checkAccess } = useAccessChecker();

    const [activeTab, setActiveTab] = useState('Tab1');

    useEffect(() => {
        const tab = localStorage.getItem('tabMachines')
        if (tab) {
            setActiveTab(tab)

        }

    }, [])

    const handleTabClick = (tab) => {
        localStorage.setItem('tabMachines', tab)
        setActiveTab(tab);
    };

    return (
        <div className="flex flex-col w-[100%] justify-start p-6  h-[100vh]">
            <ul className="flex md:flex-row flex-col justify-start items-center gap-2">
                {checkAccess('generateur', 'read') &&
                    <li
                        className={` cursor-pointer rounded-full flex w-fit justify-center items-center gap-1 py-2 px-4 text-sm font-semibold  ${activeTab === 'Tab1' ? 'text-blue-600 bg-blue-400/15 dark:bg-blue-900/20 dark:text-blue-400 hover:bg-gray-400/10 dark:hover:bg-blue-900/10' : 'text-gray-800 shadow dark:text-white bg-gray-50 dark:bg-white/5 hover:bg-gray-100 dark:hover:bg-white/10'} focus:outline-none `}
                        onClick={() => handleTabClick('Tab1')}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-air-vent"><path d="M6 12H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" /><path d="M6 8h12" /><path d="M18.3 17.7a2.5 2.5 0 0 1-3.16 3.83 2.53 2.53 0 0 1-1.14-2V12" /><path d="M6.6 15.6A2 2 0 1 0 10 17v-5" /></svg>          {t("Generateurs")}
                    </li>
                }

                {checkAccess('traitement_eau', 'read') &&

                    <li
                        className={` cursor-pointer rounded-full flex w-fit justify-center items-center gap-1 py-2 px-4 text-sm font-semibold  ${activeTab === 'Tab2' ? 'text-blue-600 bg-blue-400/15 dark:bg-blue-900/20 dark:text-blue-400 hover:bg-gray-400/10 dark:hover:bg-blue-900/10' : 'text-gray-800 shadow dark:text-white bg-gray-50 dark:bg-white/5 hover:bg-gray-100 dark:hover:bg-white/10'} focus:outline-none `}
                        onClick={() => handleTabClick('Tab2')}
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-lamp-ceiling"><path d="M12 2v5" /><path d="M6 7h12l4 9H2l4-9Z" /><path d="M9.17 16a3 3 0 1 0 5.66 0" /></svg>
                        {t("Traitement D'eau")}
                    </li>

                }
            


            </ul>
            <div className="tab-content">

                {activeTab === 'Tab1' && <div><Generateur /></div>}
                {activeTab === 'Tab2' && <div><TraitementDeau /></div>}
            </div>
        </div>)
}

export default Settings