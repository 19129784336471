// Authorization.js
import useCurrentUser from '../hooks/useCurrentUser';

export const useAccessChecker = () => {
  const { currentUser } = useCurrentUser();

  const checkAccess = (section, action) => {
    if (currentUser) {
      const permission = currentUser.role.permissions.find(item => item.section === section);

      if (!permission || !permission.access[action]) {
        return false;
      }

      return true;
    }

    return false;
  };

  return { checkAccess };
};
