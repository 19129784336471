import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import {  Select } from '../../components';
import { DeleteModal } from '../../components';
import ReactPaginate from 'react-paginate';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';


function DisplayConsomable() {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;

  const { checkAccess } = useAccessChecker();

  const [ConsomableId, setConsomableId] = useState(null)

  const [searchValue, setSearchValue] = useState(null)

  const [loading, setLoding] = useState(false)
  const [Consomable, setConsomable] = useState([])
  const [done, setDone] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)
  const [filter, setFilter] = useState({ zone: null, departement: null, agence: null, article: null, prestation: null, dateRange: null, Machine: null, typeMachine: null })
  const [filterModal, setFilterModal] = useState(false)

  const [departements, setDepartements] = useState([])
  const [articles, setArticles] = useState([])
  const [prestations, setPrestation] = useState([])
  const [agences, setAgences] = useState([])
  const [zones, setZones] = useState([])
  const [machines, setMachines] = useState([])



  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // change this as needed
  const pageCount = Math.ceil(Consomable.length / itemsPerPage);
  const displayedItems = Consomable.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );


  const MachiesTypes = [{ name: t('Generateur'), _id: 'Generateur' }, { name: t('TraitementDeau'), _id: 'TraitementDeau' }, { name: t('Autres'), _id: 'Autres' }]

  useEffect(() => {
    getConsomable(filter)

  }, [ConsomableId, done])

  const getConsomable = async (filters) => {
    try {
      setLoding(true)
      const response = await axiosInstance.post('/consomable/search', filters);
      const responseData = response.data

      if (responseData && responseData.statusCode === 200) {
        setConsomable(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };



  const find = async (e) => {
    try {
      e.preventDefault()
      setLoding(true)
      const response = await axiosInstance.post(`/consomable/find`, { searchValue });
      const responseData = response.data

      if (responseData && responseData.statusCode === 200) {
        setConsomable(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };



  const reset = async () => {
    setFilter({ zone: null, departement: null, agence: null, article: null, prestation: null, dateRange: null, Machine: null, typeMachine: null })
    setSearchValue('')
    getConsomable({ zone: null, departement: null, agence: null, article: null, prestation: null, dateRange: null })
  }

  const formatDate = (date) => {
    if (!date) return '';

    const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const timeOptions = { hour: '2-digit', minute: '2-digit' };

    const formattedDate = new Date(date).toLocaleDateString('en-US', dateOptions);
    const formattedTime = new Date(date).toLocaleTimeString('en-US', timeOptions);

    return `${formattedDate}, ${formattedTime}`;
  };

  const handleChangeFilter = async (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });
    if (e.target.name === "typeMachine") {
      if (e.target.value === 'Sanitaires') await fetchDataPost('/sanitaire/search', setMachines);
      else if (e.target.value === 'Vmc') await fetchDataPost('/vmc/search', setMachines);
      else if (e.target.value === 'Lampes') await fetchDataPost('/lampes/search', setMachines);
      else if (e.target.value === 'Climatiseurs') await fetchDataPost('/climas/search', setMachines);

    }
  }
  const handleChangeFilterDate = async (dates, dateStrings) => {
    setFilter({ ...filter, dateRange: dates });

  }
  const hanldleZoneChange = (e) => {
    const value = e.target.value;
    const zone = zones.find(item => item._id === value)
    let departements = []
    if (zone) {
      setFilter({ ...filter, [e.target.name]: value });
      zone.departements.forEach(item => {
        departements.push({ _id: item, name: item })
      })
    }

    setDepartements(departements)
  }
  const fetchDataPost = async (url, setter) => {
    try {
      setLoding(true);
      const response = await axiosInstance.post(url, { agence: filter.agence });
      const responseData = response.data;

      if (responseData && responseData.statusCode === 200) {
        setter(responseData.data);
      }
      setLoding(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoding(false);
    }
  };


  const fetchData = async (endpoint, setStateFunction) => {
    try {
      setLoding(true);
      const response = await axiosInstance.get(endpoint);
      const responseData = response.data;
      if (responseData && responseData.statusCode === 200) {
        setStateFunction(responseData.data);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoding(false);
    }
  };

  useEffect(() => {
    fetchData('/zones/search', setZones);
    fetchData('/agences/search', setAgences);
    fetchData('/prestation/search', setPrestation);
    fetchData('/consomableSettings/search', setArticles);
  }, []);



  return (
    <div className="flex flex-col w-[100%] justify-start  py-6  h-[100vh]">
      <div className="sm:flex sm:items-center mb-6 sm:justify-between">
        <div className='flex flex-col justify-center items-start'>
          <div className="flex items-center gap-x-3">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Consomables Affichage")}</h2>
            <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-neutral-800 dark:text-blue-400 lowercase">{Consomable.length} {t("Consomable")}</span>
          </div>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-300"> {t('Consomables description')} </p>
        </div>

      </div>
      <div className="flex flex-col max-w-full ">
        <div className="-m-1.5 ">
          <div className=" w-full  inline-block align-middle">
            <div className="border rounded-lg divide-y overflow-hidden max-w-full  bg-white dark:bg-neutral-800 divide-neutral-200 dark:border-neutral-700 dark:divide-neutral-700">
              <div className="py-3 px-4  ">

                <div className='flex justify-start items-center gap-2 '>
                  <div className="relative md:w-1/2 w-full">
                    <label htmlFor="hs-table-search" className="sr-only">{t("Search")}</label>
                    <input type="search" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} name="hs-table-search" id="hs-table-search" className="py-2 cursor-pointer px-3 ps-9 block w-full border border-neutral-200 bg-neutral-50 h-14 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" placeholder={t("Search for items")} />
                    <a onClick={(e) => reset(e)} className="absolute top-4 left-2 z-50 flex items-center ">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 mr-2 z-45  text-gray-800 dark:text-neutral-100">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                      </svg>


                    </a>
                    <button onClick={(e) => find(e)} disabled={!searchValue} className="block h-10 absolute top-2 right-2 flex items-center  justify-center  md:px-6 px-3 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg   dark:bg-neutral-100 lowercase">{t("Search")}</button>

                  </div>
                  <button onClick={() => setFilterModal(!filterModal)} className={` flex justify-center hover:scale-105 transition-all duration-400 items-center p-3 text-white  bg-black dark:text-black rounded-lg   dark:bg-neutral-100 `}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                    </svg>


                  </button>
                </div>
                <AnimatePresence>

                  {filterModal &&
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      className='flex gap-3 md:flex-row flex-col transition-all duration-300 justify-start mt-3 items-center'>

                      <Select label={t("Zone")} disabled={false} options={zones} name="zone" handleChange={hanldleZoneChange} defaultV={filter.zone || null} />
                      <Select label={t("Departement")} disabled={departements.length > 0 ? false : true} options={departements} name="departement" handleChange={handleChangeFilter} defaultV={filter.departement || null} />
                      <Select label={t("Agence")} options={agences} name="agence" handleChange={handleChangeFilter} defaultV={filter.agence} />
                      <Select label={t("Type Machine")} disabled={!filter.agence} options={MachiesTypes} name="typeMachine" handleChange={handleChangeFilter} defaultV={filter.typeMachine || null} />
                      <Select label={t("Machine")} disabled={filter.typeMachine === "Autres" || !filter.typeMachine} options={machines} name="Machine" handleChange={handleChangeFilter} defaultV={filter.Machine || null} />
                      <Select label={t("Nature")} options={prestations} name="manager" handleChange={handleChangeFilter} defaultV={filter.prestation} />
                      <Select label={t("Article")} options={articles} name="status" handleChange={handleChangeFilter} defaultV={filter.article || null} />
                      <RangePicker onChange={handleChangeFilterDate} className="py-3 custom-datepicker px-4 w-full h-[3.5rem] border bg-neutral-50 border-neutral-200 rounded-lg text-sm  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700  dark:focus:ring-neutral-600" />

                      <div className='flex justify-center items-center gap-3 w-full px-1'>
                        <button onClick={() => { setDone(!done) }} className="flex items-center w-1/2 justify-center gap-2  px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg   dark:bg-neutral-100">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
                          </svg>

                          <span>{t("Filter")}</span>
                        </button>
                        <button onClick={() => { reset() }} className="flex items-center gap-2 w-1/2 justify-center  px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg   dark:bg-neutral-100">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                          </svg>


                          <span>{t("Reset")}</span>
                        </button>
                      </div>
                    </motion.div>

                  }

                </AnimatePresence>


              </div>

              <div className="overflow-x-auto max-w-full">
                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700">
                  <thead className=" bg-neutral-50 dark:bg-neutral-900 h-14">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Agence")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Nature d'intervention")}</th>

                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Article")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Marque")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Section")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Quantity")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Date")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Actions")}</th>

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-neutral-200 dark:divide-neutral-700">
                    {displayedItems.length > 0 ?
                      displayedItems.map(item => {
                        return (
                          <tr key={item}>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.agence?.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.prestation?.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.article.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.article.marque}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.section}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.quantity}</td>

                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm font-medium text-gray-800 dark:text-gray-200">

                              <div className='flex justify-center items-center w-fit text-[13px] px-3 gap-1 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-100 text-green-600 py-2'>
                                {formatDate(item.createdAt)}

                              </div>



                            </td>

                            <td className="px-6 py-4 whitespace-nowrap text-start text-end text-sm font-medium">
                              <div className='flex justify-start items-center gap-2'>
                                {checkAccess('consomable', 'delete') &&

                                  <a onClick={() => { setConsomableId(item._id); setDeleteModal(true) }} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-red-600 dark:bg-opacity-10 rounded-full bg-red-100 text-red-600'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                  </a>
                                }
                                <Link to={`${item.section === 'Correctif' ? `/Correctives/${item.correctif._id}` : `/Preventives/${item.preventif._id}`}`} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600'>

                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-link"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" /><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" /></svg>                                  </Link>

                              </div>
                            </td>
                          </tr>
                        )
                      })
                      :
                      <tr className="w-full">
                        <td colSpan="10" className="px-6 py-14 animate-pulse  text-gray-500 dark:text-gray-500 text-3xl text-center font-medium">
                          {t("There is no data to be displayed !!!!")}
                        </td>
                      </tr>

                    }


                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="relative my-6">
            {displayedItems.length > 0 &&

              <ReactPaginate
                pageCount={pageCount}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName=" w-full flex justify-center  items-center "
                pageClassName="border dark:border-neutral-700 flex justify-center items-center ml-2 w-8  h-8 rounded-full"
                activeClassName="bg-green-500 text-white"
                previousClassName="bg-white dark:bg-neutral-900 dark:text-white border flex justify-center items-center text-neutral-800 hover:text-green-500 border-gray-200 dark:border-neutral-700 absolute left-0 px-6 py-2 rounded-full"
                nextClassName="bg-white dark:bg-neutral-900 dark:text-white border flex justify-center items-center text-neutral-800 hover:text-green-500 border-gray-200 dark:border-neutral-700 absolute right-0   px-6 py-2 rounded-full"
                disabledClassName="opacity-50 cursor-not-allowed"
                breakClassName="mx-2"
                pageLinkClassName="hover:text-green-500"
                previousLinkClassName="hover:text-green-500"
                nextLinkClassName="hover:text-green-500"
                breakLinkClassName="hover:text-green-500"
              />
            }
          </div>
        </div>
      </div>



      <DeleteModal setDeleteId={setConsomableId} open={deleteModal} setOpen={setDeleteModal} DeleteId={ConsomableId} Model='consomable' setDone={setDone} setLoding={setLoding} done={done} />

    </div >


  )
}

export default DisplayConsomable