import React, { useState } from 'react'
import { DarkMode, UserDropDown, LanguageDropDown, Notifications } from '../../components'
import { useTranslation } from 'react-i18next';

import useCurrentUser from '../../utils/hooks/useCurrentUser';
import { useAccessChecker } from '../../utils/Functions/Authorization';



const Navbar = ({ open, setOpen }) => {
  const { currentUser } = useCurrentUser();
  const { checkAccess } = useAccessChecker();
  const { t } = useTranslation();


  const currentDate = new Date();

  const [loginDate, setLoginDate] = useState(currentDate)

  return (
    <nav className={`flex fixed  ${open ? 'md:pl-64' : 'md:pl-[5.5rem]'} z-30 w-full transition-all duration-300 bg-white  h-[5rem] dark:bg-neutral-800  justify-between border-b border-dashed border-neutral-200 dark:border-neutral-700 `}>
      <div className='flex justify-start items-center px-6 text-gray-800 dark:text-gray-200 gap-6'>
        <a onClick={() => setOpen(!open)} className='cursor-pointer'>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-align-left"><line x1="21" x2="3" y1="6" y2="6" /><line x1="15" x2="3" y1="12" y2="12" /><line x1="17" x2="3" y1="18" y2="18" /></svg>

        </a>
        <div className='md:flex hidden flex-col gap-1 justify-center items-start '>
          <h2 className='text-[20px] font-[600] text-gray-700 dark:text-gray-300'> <span className='font-[500]'>{t('Welcome back')},</span> {currentUser?.fullName} 👋</h2>
          {loginDate && <span className="block text-sm font-medium text-gray-400 dark:text-gray-500">{t("You are logged in at:")} {new Date(loginDate).toUTCString().replace(' GMT', '')}</span>}
        </div>
      </div>

      <div className='flex justify-center items-center gap-2 z-50 px-6'>
        {checkAccess('notifications', 'read') &&
          <Notifications />
        }
        <DarkMode />
        <LanguageDropDown />
        <UserDropDown />

      </div>
    </nav>
  )
}

export default Navbar
