import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { useTranslation } from 'react-i18next';

const PiePreventifChart = ({ theme , data }) => {
        const { t } = useTranslation();

    const chartRef = useRef(null);

    useEffect(() => {
               const chartDom = chartRef.current;
        const myChart = echarts.init(chartDom);

        const option = {
            tooltip: {
                trigger: 'item',
              
            },
            legend: {
                top: '5%',
                left: 'center',
                textStyle: {
                    color: theme === 'dark' ? '#ffffff' : '#000' // Legend text color
                }
            },
            series: [
                {
                    name: t('Nbr Preventives'),
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    padAngle: 5,
                    itemStyle: {
                        borderRadius: 10
                    },
                    label: {
                        show: false,
                        position: 'center'
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 30,
                            fontWeight: 'bold',
                            color: theme === 'dark' ? '#ffffff' : '#000' // Emphasis label text color
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: data
                }
            ],
                 color:["#115f9a",  "#22a7f0","#76c68f",  "#c9e52f", "#d0ee11", "#d0f400"]

        };

        myChart.setOption(option);

        // Handle window resize
        const handleResize = () => {
            myChart.resize();
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            myChart.dispose();
        };
    }, [theme , data]); // Add theme to dependency array

    return <div ref={chartRef} style={{ width: '100%', height: '550px' }}></div>;
};

export default PiePreventifChart;
