import React from 'react';
import { useTranslation } from 'react-i18next';

function Select({ options, label, handleChange, name, defaultV, disabled }) {
  const { t } = useTranslation();

    const selectedValue = defaultV ?? '';

    return (
        <>
            <select onChange={(e) => handleChange(e)} name={name} value={selectedValue} disabled={disabled} className="py-3 px-4 w-full h-[3.5rem] border bg-neutral-50 border-neutral-200 rounded-lg text-sm placeholder:text-transparent focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:focus:ring-neutral-600">
                {defaultV === null && <option selected value={null}>{label}</option>}
                {options &&
                    options.map(item => {
                        return (
                            <option key={item._id} value={item._id}>{t(item.name) || item.fullName || item.reference || item.type}</option>
                        );
                    })}
            </select>
        </>
    );
}

export default Select;
