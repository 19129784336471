import axiosInstance from '../axios/axiosConfig';

export const getCurrentUser = async () => {
  try {
    const response = await axiosInstance.get('/auth/current-user');
    return response.data;
  } catch (error) {
    console.error("Error fetching the current user", error);
    throw error;
  }
};
