import Routers from './Routers/Routers';
import { ThemeProvider as CustomThemeProvider } from './utils/context/ThemeContext';
import { AuthProvider } from './utils/context/AuthContext';
import { UserProvider } from './utils/context/CurrentUser';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter as Router } from 'react-router-dom';
import i18n from './i18next';
import { I18nextProvider } from 'react-i18next';

function App() {
  return (
          <div className='bg-neutral-100 dark:bg-neutral-900' style={{ textAlign: 'center', width: '100vw' }}>

    <I18nextProvider i18n={i18n}>
      <Router>
        <CustomThemeProvider>
          <AuthProvider>
            <UserProvider>
              <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <Routers />
            </UserProvider>
          </AuthProvider>
        </CustomThemeProvider>
    </Router>
  </I18nextProvider >
        </div>

  );
}

export default App;
