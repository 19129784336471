import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import ReactPaginate from 'react-paginate';
import { useTranslation } from 'react-i18next';


function ConsomableHistoryHistory() {
    const { t } = useTranslation();

    const { id, name, reference } = useParams()
    const [loading, setLoding] = useState(false)
    const [ConsomableHistory, setConsomableHistory] = useState([])
    const [done, setDone] = useState(false)


    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // change this as needed
    const pageCount = Math.ceil(ConsomableHistory.length / itemsPerPage);
    const displayedItems = ConsomableHistory.slice(
        currentPage * itemsPerPage,
        (currentPage + 1) * itemsPerPage
    );



    useEffect(() => {
        getConsomableHistory()

    }, [done])

    const getConsomableHistory = async (filters) => {
        try {
            setLoding(true)
            const response = await axiosInstance.get(`/consomableHistory/search/${id}`);
            const responseData = response.data

            if (responseData && responseData.statusCode === 200) {
                setConsomableHistory(responseData.data)
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };




    const formatDate = (date) => {
        if (!date) return '';

        const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit' };

        const formattedDate = new Date(date).toLocaleDateString('en-US', dateOptions);
        const formattedTime = new Date(date).toLocaleTimeString('en-US', timeOptions);

        return `${formattedDate}, ${formattedTime}`;
    };





    return (
        <div className="flex flex-col w-[100%] justify-start p-6   h-[100vh]">
            <div className='flex flex-col justify-center items-start'>
                <div className="flex items-center gap-x-3 pb-2">
                    <ol className="flex flex-col gap-2 items-start justify-center  whitespace-nowrap mb-2">
                        <li className="inline-flex items-center">
                            <Link to='/ConsomableGlobal' className="flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 dark:text-gray-500 dark:hover:text-blue-500 dark:focus:text-blue-500" href="#">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide flex-shrink-0 me-3 size-4 lucide-lock-keyhole"><circle cx="12" cy="16" r="1" /><rect x="3" y="10" width="18" height="12" rx="2" /><path d="M7 10V7a5 5 0 0 1 10 0v3" /></svg>
                                {name} - {reference}
                            </Link>

                        </li>

                        <li className="flex items-start justify-center  flex-col text-xl font-semibold text-gray-800 truncate dark:text-gray-200" aria-current="page">
                            <p>{t("Quantity History")}</p>
                            <p className='text-[14px] font-[400]'>{t("History description")}</p>
                        </li>

                    </ol>
                </div>
            </div>
            <div className="flex flex-col max-w-full ">
                <div className="-m-1.5 ">
                    <div className=" w-full  inline-block align-middle">
                        <div className="border rounded-lg divide-y overflow-hidden max-w-full  bg-white dark:bg-neutral-800 divide-neutral-200 dark:border-neutral-700 dark:divide-neutral-700">

                            <div className="overflow-x-auto max-w-full">
                                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700">
                                    <thead className=" bg-neutral-50 dark:bg-neutral-900 h-14">
                                        <tr>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Supplier")}</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Centre")}</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("User")}</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Section")}</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Operation Type")}</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Quantity")}</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Date")}</th>
                                            <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Actions")}</th>

                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-neutral-200 dark:divide-neutral-700">
                                        {displayedItems.length > 0 ?
                                            displayedItems.map(item => {
                                                return (
                                                    <tr key={item}>
                                                        <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.supplier?.name || 'None'}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.agence?.name || 'None'}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.user?.fullName || 'None'}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.section}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.actionType}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.quantity}</td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-start text-sm font-medium text-gray-800 dark:text-gray-200">
                                                            <div className='flex justify-center items-center w-fit text-[13px] px-3 gap-1 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-100 text-green-600 py-2'>
                                                                {formatDate(item.createdAt)}

                                                            </div>
                                                        </td>
                                                        <td className="px-6 py-4 whitespace-nowrap text-start text-end text-sm font-medium">
                                                            <div className='flex justify-start items-center gap-2'>
                                                                {(item.section === 'Correctif' || item.section === 'Preventif') && (
                                                                    <Link
                                                                        to={`/${item.section === 'Correctif' ? 'Correctives' : 'Preventives'}/${item.section === 'Correctif' ? item.correctif._id : item.preventif._id}`}
                                                                        className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600'
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            width="16"
                                                                            height="16"
                                                                            viewBox="0 0 24 24"
                                                                            fill="none"
                                                                            stroke="currentColor"
                                                                            strokeWidth="2"
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            className="lucide lucide-link"
                                                                        >
                                                                            <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71" />
                                                                            <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71" />
                                                                        </svg>
                                                                    </Link>
                                                                )}

                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr className="w-full">
                                                <td colSpan="10" className="px-6 py-14 animate-pulse  text-gray-500 dark:text-gray-500 text-3xl text-center font-medium">
                                                    {t("There is no data to be displayed !!!!")}
                                                </td>
                                            </tr>

                                        }


                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div className="relative my-6">
                        {displayedItems.length > 0 &&

                            <ReactPaginate
                                pageCount={pageCount}
                                onPageChange={({ selected }) => setCurrentPage(selected)}
                                containerClassName=" w-full flex justify-center  items-center "
                                pageClassName="border dark:border-neutral-700 flex justify-center items-center ml-2 w-8  h-8 rounded-full"
                                activeClassName="bg-green-500 text-white"
                                previousClassName="bg-white dark:bg-neutral-900 dark:text-white border flex justify-center items-center text-neutral-800 hover:text-green-500 border-gray-200 dark:border-neutral-700 absolute left-0 px-6 py-2 rounded-full"
                                nextClassName="bg-white dark:bg-neutral-900 dark:text-white border flex justify-center items-center text-neutral-800 hover:text-green-500 border-gray-200 dark:border-neutral-700 absolute right-0   px-6 py-2 rounded-full"
                                disabledClassName="opacity-50 cursor-not-allowed"
                                breakClassName="mx-2"
                                pageLinkClassName="hover:text-green-500"
                                previousLinkClassName="hover:text-green-500"
                                nextLinkClassName="hover:text-green-500"
                                breakLinkClassName="hover:text-green-500"
                            />
                        }
                    </div>
                </div>
            </div>
        </div >


    )
}

export default ConsomableHistoryHistory