import React, { useState, useEffect } from 'react';
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import { Link } from 'react-router-dom';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

function Notifications() {
    const { t } = useTranslation();


    const [loading, setLoding] = useState(false)

    const [notification, setNotification] = useState([])
    const [unReadnotification, setUnReadNotification] = useState([])

    const [done, setDone] = useState(false)

    useEffect(() => {
        getNotifications()
    }, [done])




    const getNotifications = async () => {
        try {
            setLoding(true)
            const response = await axiosInstance.get('/notification/search');
            const responseData = response.data

            if (responseData && responseData.statusCode === 200) {
                setNotification(responseData.data)
                const data = responseData.data.filter(item => item.read === false)
                setUnReadNotification(data)

            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };

    const updateManyNotifications = async () => {
        try {
            setLoding(true)
            const response = await axiosInstance.put('/notification/updateMany');
            const responseData = response.data
            if (responseData && responseData.statusCode === 200) {
                //console.log(responseData.data)
                setDone(!done)
            }
            getNotifications()
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };

    const formatDate = (date) => {
        if (!date) return '';

        const dateOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        const timeOptions = { hour: '2-digit', minute: '2-digit' };

        const formattedDate = new Date(date).toLocaleDateString('en-US', dateOptions);
        const formattedTime = new Date(date).toLocaleTimeString('en-US', timeOptions);

        return `${formattedDate}, ${formattedTime}`;
    };

    return (
        <Menu as="div" className="relative inline-block text-left "  >
            <MenuButton className="inline-flex flex text-[13px] bg-neutral-50 dark:bg-neutral-900 gap-2 text-gray-800 dark:text-gray-100 justify-center items-center border rounded-xl px-3 py-2 border-neutral-200 dark:border-neutral-800 items-center ">
                <button onClick={() => updateManyNotifications()}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-5 h-5">
                        <path fillRule="evenodd" d="M5.25 9a6.75 6.75 0 0 1 13.5 0v.75c0 2.123.8 4.057 2.118 5.52a.75.75 0 0 1-.297 1.206c-1.544.57-3.16.99-4.831 1.243a3.75 3.75 0 1 1-7.48 0 24.585 24.585 0 0 1-4.831-1.244.75.75 0 0 1-.298-1.205A8.217 8.217 0 0 0 5.25 9.75V9Zm4.502 8.9a2.25 2.25 0 1 0 4.496 0 25.057 25.057 0 0 1-4.496 0Z" clipRule="evenodd" />
                    </svg>
                    <span className="sr-only">Notifications</span>
                    {
                        unReadnotification.length > 0 &&
                        <div className="absolute inline-flex items-center justify-center w-5 h-5  p-1text-xs font-bold text-white bg-green-500 text-[10px]  rounded-full animate-pulse -top-1 -end-1 ">{unReadnotification.length}</div>

                    }
                </button>
            </MenuButton>
            <Transition
                enter="transition ease-out duration-75"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <MenuItems
                    anchor="bottom end"
                    className=" md:w-[22%] w-[95%] mx-2 h-[350px] overflow-y-auto origin-top-right rounded-xl border border-neutral-200 p-1 z-50 mt-3  dark:border-neutral-700 bg-white dark:bg-neutral-900 p-1 text-sm/6 dark:text-gray-200 text-gray-800 [--anchor-gap:var(--spacing-1)] focus:outline-none"
                >
                    <MenuItem>
                        <div className='grid place-items-center w-full p-2 gap-2'>
                            {notification.length > 0 ?
                                notification.map(item => {
                                    return (
                                        <div className="rounded-lg py-3 px-4 w-full transition relative bg-gray-100 dark:bg-white/5 hover:dark:bg-green-300/5 hover:bg-green-50">
                                            <Link to={item.url} className="block  ">
                                                <div className='flex w-full justify-between items-center'>
                                                    <p className="font-semibold dark:text-neutral-200 text-neutral-800 ">{item.title}</p>
                                                    <p className="dark:bg-green-600 mt-2 text-[12px] -mt-0.5 w-fit px-3 dark:bg-opacity-10 rounded-full bg-green-100 text-green-600">{formatDate(item.createdAt)}</p>
                                                </div>
                                                <p className=" dark:text-neutral-400 text-neutral-700">{item.description}</p>

                                            </Link>
                                        </div>
                                    )
                                })
                                :

                                <div className="rounded-lg py-3 px-4 w-full transition relative hover:dark:bg-white/5 hover:bg-neutral-100">

                                    <p className="dark:text-neutral-400 text-gray-900">{t("There is no notification !!")}</p>

                                </div>


                            }

                        </div>

                    </MenuItem>

                </MenuItems>
            </Transition>
        </Menu>


    );
}

export default Notifications;
