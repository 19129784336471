import React from 'react'
import { useTheme } from '../../utils/context/ThemeContext'
import styles from './DarkMode.module.css';

function DarkMode() {
    const { theme, toggleTheme } = useTheme();

    return (
        <div className={styles.root}>
            <label htmlFor="theme" className="theme">
                <span className="theme__toggle-wrap">
                    <input id="theme" className="theme__toggle" type="checkbox" role="switch" name="theme" defaultValue="dark"  
                    checked={theme === 'dark'}
                    onChange={toggleTheme}/>
                    <span className="theme__fill" />
                    <span className="theme__icon">
                        <span className="theme__icon-part" />
                        <span className="theme__icon-part" />
                        <span className="theme__icon-part" />
                        <span className="theme__icon-part" />
                        <span className="theme__icon-part" />
                        <span className="theme__icon-part" />
                        <span className="theme__icon-part" />
                        <span className="theme__icon-part" />
                        <span className="theme__icon-part" />
                    </span>
                </span>
            </label>

        </div>
    )
}

export default DarkMode