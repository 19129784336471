import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { SideBareLink } from '../../components'
import { ReactComponent as DashboardIcon } from '../../assets/navbar/ic_analytics.svg';
import { ReactComponent as UserIcon } from '../../assets/navbar/ic_user.svg';
import { ReactComponent as AgenceIcon } from '../../assets/navbar/ic_agence.svg';
import { ReactComponent as PreventiveIcon } from '../../assets/navbar/ic_preventives.svg';
import { ReactComponent as CorrectiveIcon } from '../../assets/navbar/ic_corectives.svg';
import { ReactComponent as RolesIcon } from '../../assets/navbar/ic_roles.svg';
import { ReactComponent as SettingsIcon } from '../../assets/navbar/ic_setting.svg';
import { ReactComponent as ConsomableIcon } from '../../assets/navbar/ic_cosomable.svg';

import logo from '../../assets/images/logo.png'
import minilogo from '../../assets/images/minilogo.png'

import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';


const SideBar = ({ open, setOpen }) => {

  const { t } = useTranslation();

  const { checkAccess } = useAccessChecker();

  const [rolesSubs, setRolesSubs] = useState([
    { url: '/Roles/new', name: t('New Role'), accessSection: 'roles', accessAction: 'create' },
    { url: '/Roles', name: t('List Roles'), accessSection: 'roles', accessAction: 'read' }
  ]);

  const [techniciensSubs, setTechniciensSubs] = useState([
    { url: '/Techniciens/new', name: t('New Technicien'), accessSection: 'users', accessAction: 'create' },
    { url: '/Techniciens', name: t('List Techniciens'), accessSection: 'users', accessAction: 'read' }
  ]);

  const [agencesSubs, setAgencesSubs] = useState([
    { url: '/Agences/new', name: t('New Centre'), accessSection: 'agences', accessAction: 'create' },
    { url: '/Agences', name: t('List Centres'), accessSection: 'agences', accessAction: 'read' }
  ]);

  return (
    <>
      <aside className={`md:flex md:z-50 z-55 fixed flex-col gap-5 bg-white dark:bg-neutral-800 ${open ? 'w-64' : 'md:w-[5.5rem] hidden z-20'}  transition-all duration-300 shadow min-h-screen border-neutral-200 dark:border-neutral-700  border-r border-dashed`}>
        <div className={`relative flex w-full ${open ? 'justify-center' : 'justify-center'}  items-center  h-[5rem] pt-2`}>
          <div className='w-full flex justify-center items-center px-3'>
            <Link to="/">
              <img src={open ? logo : logo} className={`${open ? 'h-[4.3rem]' : 'h-10'}`} />
            </Link>
          </div>
          <a onClick={() => setOpen(false)} className='dark:bg-neutral-800 md:hidden flex justify-center items-center p-1 border border-neutral-200 border-dashed dark:border-neutral-700 rounded-full  absolute -right-3.5 bg-white z-10'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className={`w-4 h-4 ${open ? '' : 'rotate-180'} transition-all duration-400 text-gray-500`}>
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
            </svg>
          </a>
        </div>

        <div className={`grid place-items-center w-full ${open ? 'px-4' : 'px-1'} gap-3  `}>

          {open &&
            <div className='flex justify-start w-full item-center'>
              <h2 className='uppercase text-[12px]  font-[700] leading-[18px] text-gray-600 dark:text-gray-400 hover:text-black uppercase'>{t("OVERVIEW")}</h2>
            </div>
          }

          <SideBareLink path='/' title={t("dashboard")} open={open} icon={<DashboardIcon />} />

          {checkAccess('users', 'read') &&
            <SideBareLink path='/Techniciens' title={t("techniciens")} subs={techniciensSubs} open={open} icon={<UserIcon />} />
          }


          {checkAccess('agences', 'read') &&
            <SideBareLink path='/Agences' title={t("Centres")} subs={agencesSubs} open={open} icon={<AgenceIcon />} />
          }

          {checkAccess('correctives', 'read') &&
            <SideBareLink path='/Correctives' title={t("Correctives")} open={open} icon={<CorrectiveIcon />} />
          }

          {checkAccess('preventives', 'read') &&
            <SideBareLink path='/Preventives' title={t("Preventives")} open={open} icon={<PreventiveIcon />} />
          }
          {checkAccess('consomable', 'read') &&
            <SideBareLink path='/ConsomableGlobal' title={t("Consomables")} open={open} icon={<ConsomableIcon />} />
          }
          {checkAccess('suppliers', 'read') &&
            <SideBareLink path='/Suppliers' title={t("Suppliers")} open={open} icon={<UserIcon />} />
          }


          {open &&
            <div className='flex justify-start w-full item-center mt-3'>
              <h2 className='uppercase text-[12px] font-[700] leading-[18px] text-gray-600 dark:text-gray-400 hover:text-black'>{t("Settings")}</h2>
            </div>
          }

          {checkAccess('roles', 'read') &&
            <SideBareLink path='/Roles' title={t("Roles")} subs={rolesSubs} open={open} icon={<RolesIcon />} />
          }



          {checkAccess('settings', 'read') &&
            <SideBareLink path='/Settings' title={t("Settings")} open={open} icon={<SettingsIcon />} />
          }


        </div>











      </aside >

      {open &&
        <div onClick={() => setOpen(false)} className='absolute top-0  w-screen h-screen bg-black/10  dark:bg-black/30 z-40 md:hidden'></div>

      }
    </>
  )
}

export default SideBar
