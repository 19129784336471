import React from 'react'
import { Menu, MenuButton, MenuItem, MenuItems, Transition } from '@headlessui/react'
import fr from '../../assets/images/France.png'
import en from '../../assets/images/Us.png'
import { useTranslation } from 'react-i18next';



function LanguageDropDown() {
    const { i18n } = useTranslation();
    const { t } = useTranslation();

    const languages = [
        { short: 'en', name: t("English"), icon: en },
        { short: 'fr', name: t("Frensh"), icon: fr },

    ]

    const currentLanguage = localStorage.getItem('i18nextLng');


    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        localStorage.setItem('i18nextLng', lng);
    };


    const getLanguage = (value) => {
        const selected = languages.find(item => item.short === value)
        return selected
    }

    return (
        <div className=" relative inline-flex ">
            <Menu>
                <MenuButton className="inline-flex flex text-[13px] bg-neutral-50 dark:bg-neutral-900 gap-2 text-gray-800 dark:text-gray-100 justify-center items-center border rounded-xl px-3 py-2 border-neutral-200 dark:border-neutral-800 items-center ">
                    <img className="inline-block size-[25px] rounded-full " src={getLanguage(currentLanguage)?.icon} alt="Image Description" />
                    {getLanguage(currentLanguage)?.name}
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-chevron-down"><path d="m6 9 6 6 6-6" /></svg>
                </MenuButton>
                <Transition
                    enter="transition ease-out duration-75"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                    <MenuItems
                        anchor="bottom end"
                        className="w-52 origin-top-right rounded-xl border border-neutral-200 p-1 z-50 mt-3  dark:border-neutral-700 bg-white dark:bg-neutral-900 p-1 text-sm/6 dark:text-gray-200 text-gray-800 [--anchor-gap:var(--spacing-1)] focus:outline-none"
                    >
                        {languages &&
                            languages.map((item , index) => {
                                return (
                                    <MenuItem key={index}>
                                        <button onClick={() => changeLanguage(item.short)} className="group flex w-full items-center gap-2 rounded-lg py-1.5 px-3  hover:bg-black/5 dark:hover:bg-white/5">
                                            <img className="inline-block size-[30px] rounded-full " src={item.icon} alt="Image Description" />
                                            {t(item.name)}
                                            <kbd className="ml-auto hidden font-sans text-xs dark:text-gray-200 text-gray-800 group-data-[focus]:inline">⌘E</kbd>
                                        </button>
                                    </MenuItem>
                                )
                            })

                        }
                    </MenuItems>
                </Transition>
            </Menu>


        </div>
    )
}

export default LanguageDropDown