import React, { useEffect, useRef } from 'react';
import * as echarts from 'echarts';
import { useTheme } from '../../utils/context/ThemeContext';
import { useTranslation } from 'react-i18next';

const PieCorrectifChart = ({ data }) => {
    const { t } = useTranslation();

    const chartRef = useRef(null);
    const { theme } = useTheme();

    useEffect(() => {
        const chartDom = chartRef.current;
        const myChart = echarts.init(chartDom);

        const textColor = theme === 'dark' ? '#ffffff' : '#000000'; // Adjust text color based on theme

        const option = {
            tooltip: {
                trigger: 'item',

            },
            legend: {
                top: '5%',
                left: 'center',
                textStyle: {
                    color: textColor // Change legend text color here
                }
            },
            series: [
                {
                    name: t('Nbr Correctives'),
                    type: 'pie',
                    radius: ['40%', '70%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: 'center',
                        color: textColor // Change series label text color here
                    },
                       padAngle: 5,
                    itemStyle: {
                        borderRadius: 10
                    },
                    emphasis: {
                        label: {
                            show: true,
                            fontSize: 30,
                            fontWeight: 'bold',
                            color: textColor // Change emphasis label text color here
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: data

                }
            ],
                 color:["#115f9a",  "#22a7f0","#76c68f",  "#c9e52f", "#d0ee11", "#d0f400"]


        };

        myChart.setOption(option);

        // Handle window resize
        const handleResize = () => {
            myChart.resize();
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
            myChart.dispose();
        };
    }, [theme, data]);

    return <div ref={chartRef} style={{ width: '100%', height: '550px' }}></div>;
};

export default PieCorrectifChart;
