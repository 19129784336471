import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { Input, Select } from '../../components';
import { DeleteModal } from '../../components';
import ReactPaginate from 'react-paginate';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import { useAccessChecker } from '../../utils/Functions/Authorization';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';


function GererConsomable() {
  const { t } = useTranslation();

  const { checkAccess } = useAccessChecker();

  const [ConsomableModal, setConsomableModal] = useState(false)
  const [ConsomableModalStock, setConsomableModalStock] = useState(false)

  const [ConsomableId, setConsomableId] = useState(null)

  const [searchValue, setSearchValue] = useState(null)
  const [Supplier, setSupplier] = useState([])
  const Types = [{ name: t('Garantie'), _id: 'Garantie' }, { name: t('Facture'), _id: 'Facture' }]
  const actionTypes = [{ name: t('Addition'), _id: 'Addition' }, { name: t('Subtraction'), _id: 'Subtraction' }]

  const [formDataStock, setFormDataStock] = useState({ supplier: null, article: null, actionType: null, quantity: null })

  const [formData, setFormData] = useState({  name: null, marque: null, reference: null, type: null, minStock: null })
  const [loading, setLoding] = useState(false)
  const [Consomable, setConsomable] = useState([])
  const [done, setDone] = useState(false)

  const [deleteModal, setDeleteModal] = useState(false)


  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10; // change this as needed
  const pageCount = Math.ceil(Consomable.length / itemsPerPage);
  const displayedItems = Consomable.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );



  useEffect(() => {
    getConsomable()
    getSupplier()

  }, [ConsomableId, done])

  const getSupplier = async () => {
    try {
      setLoding(true)
      const response = await axiosInstance.get('/supplier/search');
      const responseData = response.data

      if (responseData && responseData.statusCode === 200) {
        setSupplier(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };
  const getConsomable = async () => {
    try {
      setLoding(true)
      const response = await axiosInstance.get('/consomableSettings/search');
      const responseData = response.data

      if (responseData && responseData.statusCode === 200) {
        setConsomable(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }
  const handleChangStock = (e) => {
    setFormDataStock({ ...formDataStock, [e.target.name]: e.target.value });
  }
  const InitialiseConsomable = () => {
    setFormData({  name: null, marque: null, reference: null, type: null, minStock: null })
  }

  const InitialiseConsomableStock = () => {
    setFormDataStock({ supplier: null, article: null, actionType: null, quantity: null })
  }

  const handleToggleModal = (id) => {
    if (id) {
      setConsomableId(id)
      getConsomableToUpdate(id)

    } else {
      setConsomableModal(!ConsomableModal)
      InitialiseConsomable()

    }
  }

  const handleToggleModalStock = (id) => {

    setConsomableModalStock(!ConsomableModalStock)
    InitialiseConsomableStock()

  }

  const getConsomableToUpdate = async (id) => {
    try {
      setLoding(true)
      const response = await axiosInstance.get(`/consomableSettings/get/${id}`);
      const responseData = response.data
      if (responseData && responseData.statusCode === 200) {
        setFormData(responseData.data)
        setConsomableModal(!ConsomableModal)

      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  }
  const find = async (e) => {
    try {
      e.preventDefault()
      setLoding(true)
      const response = await axiosInstance.post(`/consomableSettings/find`, { searchValue });
      const responseData = response.data

      if (responseData && responseData.statusCode === 200) {
        setConsomable(responseData.data)
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoding(false)
    }
  };


  const save = async (e) => {
    e.preventDefault();
    try {
      setLoding(true)
      let response = null
      if (ConsomableId) {
        response = await axiosInstance.put(`/consomableSettings/update/${ConsomableId}`, formData);
      } else {
        response = await axiosInstance.post('/consomableSettings/create', formData);
      }
      const responseData = response.data
      if (responseData && responseData.statusCode === 200) {
        if (ConsomableId) {
          toast.success(responseData.message);
          setConsomableId(null)
          setConsomableModal(false)
          InitialiseConsomable()
          setDone(!done)
        } else {
          toast.success(responseData.message);
          handleToggleModal()
          setDone(!done)
        }
      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message);
      setLoding(false)
    }
  };




  const saveStock = async (e) => {
    e.preventDefault();
    try {
      setLoding(true)
      const response = await axiosInstance.post('/consomableHistory/create', formDataStock);
      const responseData = response.data
      if (responseData && responseData.statusCode === 200) {
        toast.success(responseData.message);
        handleToggleModalStock()
        setDone(!done)

      }
      setLoding(false)
    } catch (error) {
      toast.error(error.response.data.message);
      setLoding(false)
    }
  };


  const reset = () => {
    setSearchValue('')
    getConsomable()
  }
  const status = (item) => {
    if (item.quantity === 0) {
      return { status: 'Out Of Stock', color: 'red' }
    }
    else if (item.quantity > item.minStock) {
      return { status: 'Available', color: 'green' }

    }
    else if (item.quantity <= item.minStock) {
      return { status: 'Low Stock', color: 'orange' }

    }


  }

  return (
    <div className="flex flex-col w-[100%] justify-start py-6  h-[100vh]">
      <div className="sm:flex sm:items-center mb-6 sm:justify-between">
        <div className='flex flex-col justify-center items-start'>
          <div className="flex items-center gap-x-3">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Consomable Management")}</h2>
            <span className="px-3 py-1 text-xs text-blue-600 bg-blue-100 rounded-full dark:bg-neutral-800 dark:text-blue-400 lowercase">{Consomable.length} {t("Consomable")}</span>
          </div>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-300"> {t("Consomable description")} </p>
        </div>
        <div className="flex items-center mt-4 gap-x-3">
          {checkAccess('consomableSettings', 'create') &&
            <>


              <button onClick={() => { handleToggleModalStock() }} className="flex items-center justify-center w-1/2 px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>{t("Manage Stock")}</span>
              </button>
              <button onClick={() => { handleToggleModal() }} className="flex items-center justify-center w-1/2 px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
                <span>{t("Add Consomable")}</span>
              </button>
            </>

          }

        </div>
      </div>
      <div className="flex flex-col max-w-full ">
        <div className="-m-1.5 ">
          <div className=" w-full  inline-block align-middle">
            <div className="border rounded-lg divide-y overflow-hidden max-w-full  bg-white dark:bg-neutral-800 divide-neutral-200 dark:border-neutral-700 dark:divide-neutral-700">
              <div className="py-3 px-4  ">
                <div className="relative md:w-1/2 w-full">
                  <label htmlFor="hs-table-search" className="sr-only">{t("Search")}</label>
                  <input type="search" onChange={(e) => setSearchValue(e.target.value)} value={searchValue} name="hs-table-search" id="hs-table-search" className="py-2 cursor-pointer px-3 ps-9 block w-full border border-neutral-200 bg-neutral-50 h-14 shadow-sm rounded-lg text-sm focus:z-10 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700 dark:text-gray-400 dark:placeholder-neutral-500 dark:focus:ring-neutral-600" placeholder={t("Search for items")} />
                  <a onClick={(e) => reset(e)} className="absolute top-4 left-2 z-50 flex items-center ">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5 mr-2 z-45  text-gray-800 dark:text-neutral-100">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                    </svg>


                  </a>
                  <button onClick={(e) => find(e)} disabled={!searchValue} className="block h-10 absolute top-2 right-2 flex items-center  justify-center  md:px-6 px-3 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg   dark:bg-neutral-100 lowercase">{t("Search")}</button>

                </div>
              </div>
              <div className="overflow-x-auto max-w-full">
                <table className="min-w-full divide-y divide-neutral-200 dark:divide-neutral-700">
                  <thead className=" bg-neutral-50 dark:bg-neutral-900 h-14">
                    <tr>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Marque")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Name")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Reference")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Type")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Quantity")}</th>
                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Status")}</th>

                      <th scope="col" className="px-6 py-3 text-start text-xs font-medium text-gray-500 uppercase dark:text-gray-500">{t("Actions")}</th>

                    </tr>
                  </thead>
                  <tbody className="divide-y divide-neutral-200 dark:divide-neutral-700">
                    {displayedItems.length > 0 ?
                      displayedItems.map(item => {
                        return (
                          <tr key={item}>

                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.marque}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.name}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.reference}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200">{item.type}</td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm text-gray-800 dark:text-gray-200"><div className='px-8 py-2 w-fit flex justify-center  items-center rounded-full text-[14px] font-[700] text-blue-700 bg-blue-700/10'>{item.quantity}</div></td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-sm ">
                              <div className={` flex justify-center hover:scale-110 transition-all duration-400 items-center px-8 py-2 dark:bg-${status(item).color}-600 dark:bg-opacity-10 w-fit rounded-full bg-${status(item).color}-100 text-${status(item).color}-600`}>
                                {status(item).status}
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap text-start text-end text-sm font-medium">
                              <div className='flex justify-start items-center gap-2'>
                                {checkAccess('consomableSettings', 'delete') &&

                                  <a onClick={() => { setConsomableId(item._id); setDeleteModal(true) }} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-red-600 dark:bg-opacity-10 rounded-full bg-red-100 text-red-600'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-trash-2"><path d="M3 6h18" /><path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" /><path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" /><line x1="10" x2="10" y1="11" y2="17" /><line x1="14" x2="14" y1="11" y2="17" /></svg>
                                  </a>
                                }
                                {checkAccess('consomableSettings', 'update') &&

                                  <a onClick={() => handleToggleModal(item._id)} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-green-600 dark:bg-opacity-10 rounded-full bg-green-100 text-green-600'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-pencil"><path d="M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z" /><path d="m15 5 4 4" /></svg>
                                  </a>
                                }

                                <Link to={`/ConsomableHistory/${item._id}/${item.name}/${item.reference}`} title='Correctif Details' className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-yellow-600 dark:bg-opacity-10 rounded-full bg-yellow-100 text-yellow-600'>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="lucide lucide-eye"><path d="M2 12s3-7 10-7 10 7 10 7-3 7-10 7-10-7-10-7Z" /><circle cx="12" cy="12" r="3" /></svg>
                                </Link>


                              </div>
                            </td>
                          </tr>
                        )
                      })
                      :
                      <tr className="w-full">
                        <td colSpan="10" className="px-6 py-14 animate-pulse  text-gray-500 dark:text-gray-500 text-3xl text-center font-medium">
                          {t("There is no data to be displayed !!!!")}
                        </td>
                      </tr>

                    }


                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="relative my-6">
            {displayedItems.length > 0 &&

              <ReactPaginate
                pageCount={pageCount}
                onPageChange={({ selected }) => setCurrentPage(selected)}
                containerClassName=" w-full flex justify-center  items-center "
                pageClassName="border dark:border-neutral-700 flex justify-center items-center ml-2 w-8  h-8 rounded-full"
                activeClassName="bg-green-500 text-white"
                previousClassName="bg-white dark:bg-neutral-900 dark:text-white border flex justify-center items-center text-neutral-800 hover:text-green-500 border-gray-200 dark:border-neutral-700 absolute left-0 px-6 py-2 rounded-full"
                nextClassName="bg-white dark:bg-neutral-900 dark:text-white border flex justify-center items-center text-neutral-800 hover:text-green-500 border-gray-200 dark:border-neutral-700 absolute right-0   px-6 py-2 rounded-full"
                disabledClassName="opacity-50 cursor-not-allowed"
                breakClassName="mx-2"
                pageLinkClassName="hover:text-green-500"
                previousLinkClassName="hover:text-green-500"
                nextLinkClassName="hover:text-green-500"
                breakLinkClassName="hover:text-green-500"
              />
            }
          </div>
        </div>
      </div>


      <AnimatePresence>

        {ConsomableModal &&
          <div
            className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-80 inset-0 z-0" />
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              className="w-full md:w-[40%] py-4 relative mx-auto mx-2  rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
              <div className="flex  flex-col justify-center items-center w-full px-6 py-3">
                <div className="flex w-full justify-between items-center gap-x-3 mb-6">
                  <h2 className="text-[20px] font-[700] text-gray-800 dark:text-white">{t("Add Consomable")}</h2>
                  <a onClick={() => handleToggleModal()} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>
                </div>
                <div className='grid md:grid-cols-2 w-full place-items-center gap-3 '>

                  <Input type='text' disabled={false} label={t("Marque")} name="marque" handleChange={handleChange} defaultV={formData.marque || null} />
                  <Input type='text' disabled={false} label={t("Name")} name="name" handleChange={handleChange} defaultV={formData.name || null} />
                  <Input type='text' disabled={false} label={t("Reference")} name="reference" handleChange={handleChange} defaultV={formData.reference || null} />
                  <Input type='number' disabled={false} label={t("Minimum Stock Level")} name="minStock" handleChange={handleChange} defaultV={formData.minStock || null} />
                  <Select label={t("Type")} disabled={false} options={Types} name="type" handleChange={handleChange} defaultV={formData.type || null} />



                </div>

                <div className='md:col-span-3 w-full flex items-center justify-end '>
                  <div className="flex items-center mt-6 gap-x-3">
                    <button onClick={(e) => save(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                      {
                        !loading ?
                          (
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              <span>{t("Save consomable")}</span>
                            </>
                          )
                          :

                          (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                            <span className="sr-only">Loading...</span>
                          </div>)

                      }

                    </button>
                  </div>
                </div>

              </div>
            </motion.div>
          </div>
        }




        {ConsomableModalStock &&
          <div
            className="min-w-screen h-screen animated fadeIn faster  fixed  left-0 top-0 flex justify-center items-center inset-0 z-50 outline-none focus:outline-none bg-no-repeat bg-center bg-cover" id="modal-id">
            <div className="absolute bg-black opacity-80 inset-0 z-0" />
            <motion.div
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{ duration: 0.3 }}
              className="w-full md:w-[40%] py-4 relative mx-auto mx-2  rounded-xl shadow-lg  bg-white dark:bg-neutral-800 ">
              <div className="flex  flex-col justify-center items-center w-full px-6 py-3">
                <div className="flex w-full justify-between items-center gap-x-3 mb-6">
                  <h2 className="text-[20px] font-[700] text-gray-800 dark:text-white">{t("Manage Stock Consomable")}</h2>
                  <a onClick={() => handleToggleModalStock()} className='flex justify-center hover:scale-110 transition-all duration-400 items-center p-2 dark:bg-blue-600 dark:bg-opacity-10 rounded-full bg-blue-100 text-blue-600'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-x"><path d="M18 6 6 18" /><path d="m6 6 12 12" /></svg>                                    </a>
                </div>
                <div className='grid md:grid-cols-2 w-full place-items-center gap-3 '>
                  <Select label={t("Supplier")} disabled={false} options={Supplier} name="supplier" handleChange={handleChangStock} defaultV={formDataStock.supplier || null} />
                  <Select label={t("Article")} disabled={false} options={Consomable} name="article" handleChange={handleChangStock} defaultV={formDataStock.article || null} />
                  <Select label={t("Operation Type")} disabled={false} options={actionTypes} name="actionType" handleChange={handleChangStock} defaultV={formDataStock.actionType || null} />
                  <Input type='number' disabled={false} label={t("Quantity")} name="quantity" handleChange={handleChangStock} defaultV={formDataStock.minStock || null} />



                </div>

                <div className='md:col-span-3 w-full flex items-center justify-end '>
                  <div className="flex items-center mt-6 gap-x-3">
                    <button onClick={(e) => saveStock(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                      {
                        !loading ?
                          (
                            <>
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                              </svg>
                              <span>{t("Save ")}</span>
                            </>
                          )
                          :

                          (<div className="animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-white rounded-full" role="status" aria-label="loading">
                            <span className="sr-only">Loading...</span>
                          </div>)

                      }

                    </button>
                  </div>
                </div>

              </div>
            </motion.div>
          </div>
        }
      </AnimatePresence >


      <DeleteModal setDeleteId={setConsomableId} open={deleteModal} setOpen={setDeleteModal} DeleteId={ConsomableId} Model='consomable' setDone={setDone} setLoding={setLoding} done={done} />

    </div >


  )
}

export default GererConsomable