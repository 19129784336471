import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { motion, AnimatePresence } from 'framer-motion';
import axiosInstance from '../../utils/axios/axiosConfig';
import useCurrentUser from '../../utils/hooks/useCurrentUser';
import { toast } from 'react-toastify';
import PieCorrectifChart from './PieCorrectifChart';
import PiePreventifChart from './PiePreventifChart';
import { useTheme } from '../../utils/context/ThemeContext'
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';
import { Input, Select } from '../../components';
import CorrectifPerPrestation from './CorrectifPerPrestation';
import ConssomablePerPrestation from './ConssomablePerPrestation';
import CorrectivesPerQuarter from './CorrectivesPerQuarter';
import PreventifPerQaurter from './PreventifPerQaurter';

function Dashboard() {
  const { t } = useTranslation();

  const { theme, toggleTheme } = useTheme();

  const { currentUser } = useCurrentUser();
  const [Loading, setLoading] = useState(false)
  const [cardsCount, setCardsCount] = useState([])
  const [data, setData] = useState([])
  const [filter, setFilter] = useState({ zone: null, departement: null, agence: null, trimestre: null })
  const [departements, setDepartements] = useState([])
  const [zones, setZones] = useState([])
  const [agences, setAgences] = useState([])
  const [CorrectivesPerPrestation, setCorrectivesPerPrestation] = useState([])
  const [ConsomablesPerPrestation, setConsomablesPerPrestation] = useState([])
  const [correctivesPerQuarter, setcorrectivesPerQuarter] = useState([])
  const [preventifsPerQuarter, setpreventifsPerQuarter] = useState([])


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          getCardsCount(),
          getStats(),
          getAgences(),
          getZones(),
          getCorrectivesPerPrestation(),
          getConsomablesPerPrestation(),
          getCorrectivesPerQuarter(),
          getpreventifsPerQuarter()
        ]);
        setLoading(false);
      } catch (error) {
        toast.error(error.response.data.message);
        setLoading(false);
      }
    };

    fetchData();

  }, [])

  const fetchData = async (url, setter) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(url);
      const responseData = response.data;

      if (responseData && responseData.statusCode === 200) {
        setter(responseData.data);
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  };

  const getZones = async () => {
    await fetchData('/zones/search', setZones);
  };
  const getAgences = async () => {
    await fetchData('/agences/search', setAgences);
  };

  const handleChangeTrimestreFilter = async (value, dateString) => {
    setFilter({ ...filter, trimestre: dateString });

  }

  const handleChangeFilter = async (e) => {
    setFilter({ ...filter, [e.target.name]: e.target.value });

  }


  const reset = (e) => {
    setFilter({ zone: null, departement: null, agence: null, trimestre: null })
    filterData(e)
  }



  const hanldleZoneChange = (e) => {
    const value = e.target.value;
    const zone = zones.find(item => item._id === value)
    let departements = []
    if (zone) {
      setFilter({ ...filter, [e.target.name]: value });
      zone.departements.forEach(item => {
        departements.push({ _id: item, name: item })
      })
    }

    setDepartements(departements)
  }



  const getCardsCount = async (filter) => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(`/statistiques/cardsCount`, filter);
      const responseData = response.data
      if (responseData && responseData.statusCode === 200) {
        setCardsCount(responseData.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  };
  const getCorrectivesPerPrestation = async (filter) => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(`/statistiques/correctivesPerPrestation`, filter);
      const responseData = response.data
      if (responseData && responseData.statusCode === 200) {
        setCorrectivesPerPrestation(responseData.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  };
  const getpreventifsPerQuarter = async (filter) => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(`/statistiques/preventifsPerQuarter`, filter);
      const responseData = response.data
      if (responseData && responseData.statusCode === 200) {
        setpreventifsPerQuarter(responseData.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  };

  const getConsomablesPerPrestation = async (filter) => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(`/statistiques/consomablesPerPrestation`, filter);
      const responseData = response.data
      if (responseData && responseData.statusCode === 200) {
        setConsomablesPerPrestation(responseData.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  };
  const getCorrectivesPerQuarter = async (filter) => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(`/statistiques/correctivesPerQuarter`, filter);
      const responseData = response.data
      if (responseData && responseData.statusCode === 200) {
        setcorrectivesPerQuarter(responseData.data)
        //console.log('ertuio--------------', correctivesPerQuarter)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  };

  const getStats = async (filter) => {
    try {
      setLoading(true)
      const response = await axiosInstance.post(`/statistiques/PieAnalytics`, filter);
      const responseData = response.data
      if (responseData && responseData.statusCode === 200) {
        setData(responseData.data)
      }
      setLoading(false)
    } catch (error) {
      toast.error(error.response.data.message)
      setLoading(false)
    }
  };


  const filterData = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await Promise.all([
        getCardsCount(filter),
        getStats(filter),
        getCorrectivesPerPrestation(filter),
        getConsomablesPerPrestation(filter),
        getCorrectivesPerQuarter(filter),
        getpreventifsPerQuarter(filter)
      ]);
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
      setLoading(false);
    }
  }

  return (
    <div className="flex flex-col w-[100%] justify-start p-6 mb-6  h-[100vh]">
      <div className='flex flex-col justify-center items-start'>
        <div className="flex items-center gap-x-3">
          <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Dashbord")}</h2>
        </div>
        <p className="mt-1 text-sm text-gray-500 dark:text-gray-300"> {t("dashboard description")} </p>
      </div>

      <div className='flex md:flex-row flex-col justify-start items-center gap-5 mt-5 w-full'>
        {currentUser?.role?.name.toLowerCase() === 'admin' &&
          <>
            <div className="flex justify-between items-center  bg-white dark:bg-neutral-800 text-gray-800 dark:text-neutral-100  rounded-xl w-full md:w-1/4 px-8 py-6">
              <div className="flex flex-col justify-center items-start ">
                <h4 className="text-2xl font-semibold   ">{cardsCount.users}</h4>
                <p className="">{t("Users")}</p>

              </div>

              <div className=" flex justify-center hover:scale-110 transition-all duration-400 items-center p-3 dark:bg-green-600 dark:bg-opacity-10 rounded-xl bg-green-100 text-green-600 ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-7">
                  <path fill-rule="evenodd" d="M8.25 6.75a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0ZM15.75 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM2.25 9.75a3 3 0 1 1 6 0 3 3 0 0 1-6 0ZM6.31 15.117A6.745 6.745 0 0 1 12 12a6.745 6.745 0 0 1 6.709 7.498.75.75 0 0 1-.372.568A12.696 12.696 0 0 1 12 21.75c-2.305 0-4.47-.612-6.337-1.684a.75.75 0 0 1-.372-.568 6.787 6.787 0 0 1 1.019-4.38Z" clip-rule="evenodd" />
                  <path d="M5.082 14.254a8.287 8.287 0 0 0-1.308 5.135 9.687 9.687 0 0 1-1.764-.44l-.115-.04a.563.563 0 0 1-.373-.487l-.01-.121a3.75 3.75 0 0 1 3.57-4.047ZM20.226 19.389a8.287 8.287 0 0 0-1.308-5.135 3.75 3.75 0 0 1 3.57 4.047l-.01.121a.563.563 0 0 1-.373.486l-.115.04c-.567.2-1.156.349-1.764.441Z" />
                </svg>

              </div>
            </div>


            <div className="flex justify-between items-center w-full bg-white dark:bg-neutral-800 text-gray-800 dark:text-neutral-100  rounded-xl  md:w-1/4 px-8 py-6">
              <div className="flex flex-col justify-center items-start ">
                <h4 className="text-2xl font-semibold   ">{cardsCount.agence}</h4>
                <p className="">{t("Agences")}</p>

              </div>

              <div className=" flex justify-center hover:scale-110 transition-all duration-400 items-center p-3 dark:bg-orange-600 dark:bg-opacity-10 rounded-xl bg-orange-100 text-orange-600 ">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-7">
                  <path fill-rule="evenodd" d="M3 2.25a.75.75 0 0 0 0 1.5v16.5h-.75a.75.75 0 0 0 0 1.5H15v-18a.75.75 0 0 0 0-1.5H3ZM6.75 19.5v-2.25a.75.75 0 0 1 .75-.75h3a.75.75 0 0 1 .75.75v2.25a.75.75 0 0 1-.75.75h-3a.75.75 0 0 1-.75-.75ZM6 6.75A.75.75 0 0 1 6.75 6h.75a.75.75 0 0 1 0 1.5h-.75A.75.75 0 0 1 6 6.75ZM6.75 9a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75ZM6 12.75a.75.75 0 0 1 .75-.75h.75a.75.75 0 0 1 0 1.5h-.75a.75.75 0 0 1-.75-.75ZM10.5 6a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75Zm-.75 3.75A.75.75 0 0 1 10.5 9h.75a.75.75 0 0 1 0 1.5h-.75a.75.75 0 0 1-.75-.75ZM10.5 12a.75.75 0 0 0 0 1.5h.75a.75.75 0 0 0 0-1.5h-.75ZM16.5 6.75v15h5.25a.75.75 0 0 0 0-1.5H21v-12a.75.75 0 0 0 0-1.5h-4.5Zm1.5 4.5a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Zm.75 2.25a.75.75 0 0 0-.75.75v.008c0 .414.336.75.75.75h.008a.75.75 0 0 0 .75-.75v-.008a.75.75 0 0 0-.75-.75h-.008ZM18 17.25a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z" clip-rule="evenodd" />
                </svg>


              </div>
            </div>
          </>
        }
        <div className={`flex justify-between items-center w-full bg-white dark:bg-neutral-800 text-gray-800 dark:text-neutral-100  rounded-xl ${currentUser?.role?.name.toLowerCase() === 'admin' ? 'md:w-1/4' : 'md:w-1/2'}  px-8 py-6`}>
          <div className="flex flex-col justify-center items-start ">
            <h4 className="text-2xl font-semibold   ">{cardsCount.correctif}</h4>
            <p className="">{t("Correctives")}</p>

          </div>

          <div className=" flex justify-center hover:scale-110 transition-all duration-400 items-center p-3 dark:bg-blue-600 dark:bg-opacity-10 rounded-xl bg-blue-100 text-blue-600 ">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-7">
              <path fill-rule="evenodd" d="M12 6.75a5.25 5.25 0 0 1 6.775-5.025.75.75 0 0 1 .313 1.248l-3.32 3.319c.063.475.276.934.641 1.299.365.365.824.578 1.3.64l3.318-3.319a.75.75 0 0 1 1.248.313 5.25 5.25 0 0 1-5.472 6.756c-1.018-.086-1.87.1-2.309.634L7.344 21.3A3.298 3.298 0 1 1 2.7 16.657l8.684-7.151c.533-.44.72-1.291.634-2.309A5.342 5.342 0 0 1 12 6.75ZM4.117 19.125a.75.75 0 0 1 .75-.75h.008a.75.75 0 0 1 .75.75v.008a.75.75 0 0 1-.75.75h-.008a.75.75 0 0 1-.75-.75v-.008Z" clip-rule="evenodd" />
              <path d="m10.076 8.64-2.201-2.2V4.874a.75.75 0 0 0-.364-.643l-3.75-2.25a.75.75 0 0 0-.916.113l-.75.75a.75.75 0 0 0-.113.916l2.25 3.75a.75.75 0 0 0 .643.364h1.564l2.062 2.062 1.575-1.297Z" />
              <path fill-rule="evenodd" d="m12.556 17.329 4.183 4.182a3.375 3.375 0 0 0 4.773-4.773l-3.306-3.305a6.803 6.803 0 0 1-1.53.043c-.394-.034-.682-.006-.867.042a.589.589 0 0 0-.167.063l-3.086 3.748Zm3.414-1.36a.75.75 0 0 1 1.06 0l1.875 1.876a.75.75 0 1 1-1.06 1.06L15.97 17.03a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
            </svg>


          </div>
        </div>

        <div className={`flex justify-between items-center w-full bg-white dark:bg-neutral-800 text-gray-800 dark:text-neutral-100  rounded-xl ${currentUser?.role?.name.toLowerCase() === 'admin' ? 'md:w-1/4' : 'md:w-1/2'}  px-8 py-6`}>
          <div className="flex flex-col justify-center items-start ">
            <h4 className="text-2xl font-semibold   ">{cardsCount.preventif}</h4>
            <p className="">{t("Preventives")}</p>

          </div>

          <div className=" flex justify-center hover:scale-110 transition-all duration-400 items-center p-3 dark:bg-red-600 dark:bg-opacity-10 rounded-xl bg-red-100 text-red-600 ">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="size-7">
              <path fill-rule="evenodd" d="M12.516 2.17a.75.75 0 0 0-1.032 0 11.209 11.209 0 0 1-7.877 3.08.75.75 0 0 0-.722.515A12.74 12.74 0 0 0 2.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.749.749 0 0 0 .374 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 0 0-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08Zm3.094 8.016a.75.75 0 1 0-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 0 0-1.06 1.06l2.25 2.25a.75.75 0 0 0 1.14-.094l3.75-5.25Z" clip-rule="evenodd" />
            </svg>

          </div>
        </div>



      </div>

      <div className='flex justify-center items-start gap-5 w-full my-5 flex-col bg-white dark:bg-neutral-800 p-4'>
        <div className='flex justify-start items-centerw-full '>
          <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Filter")}</h2>
        </div>
        <div className='flex justify-center items-center gap-5 w-full md:flex-row flex-col '>

          <Select label={t("Zone")} disabled={false} options={zones} name="zone" handleChange={hanldleZoneChange} defaultV={filter.zone || null} />
          <Select label={t("Departement")} disabled={departements.length > 0 ? false : true} options={departements} name="departement" handleChange={handleChangeFilter} defaultV={filter.departement || null} />
          <DatePicker onChange={handleChangeTrimestreFilter} defaultValue={filter.trimestre} format="YYYY-[Q]Q" name='trimestre' picker="quarter" className="py-3 custom-datepicker px-4 w-full h-[3.5rem] border bg-neutral-50 border-neutral-200 rounded-lg text-sm  focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-900 dark:border-neutral-700  dark:focus:ring-neutral-600" />

          <div className='flex justify-center items-center gap-3 w-full px-1'>
            <button onClick={(e) => { filterData(e) }} className="flex items-center w-1/2 justify-center gap-2  px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg   dark:bg-neutral-100">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z" />
              </svg>

              <span>{t("Filter")}</span>
            </button>
            <button onClick={(e) => { reset(e) }} className="flex items-center gap-2 w-1/2 justify-center  px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg   dark:bg-neutral-100">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-5">
                <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
              </svg>


              <span>{t("Reset")}</span>
            </button>
          </div>
        </div>

      </div>


      <div className='grid md:grid-cols-2 grid-cols-1 gap-5  w-full'>
        <div className=' w-full bg-white  text-black dark:text-neutral-100 dark:bg-neutral-800 p-3 rounded-xl border border-gray-200 dark:border-neutral-700'>
          <div className="flex items-center gap-x-3 p-2">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Correctif par status")}</h2>
          </div>

          <PieCorrectifChart theme={theme} data={data.correctif} />

        </div>
        <div className=' w-full rounded-xl bg-white  text-black dark:text-neutral-100 dark:bg-neutral-800 p-3 rounded-xl border border-gray-200 dark:border-neutral-700'>
          <div className="flex items-center gap-x-3 p-2">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Preventif par status")}</h2>
          </div>
          <PiePreventifChart theme={theme} data={data.preventif} />

        </div>
        <div className=' w-full rounded-xl bg-white  text-black dark:text-neutral-100 dark:bg-neutral-800 p-3 rounded-xl border border-gray-200 dark:border-neutral-700'>
          <div className="flex items-center gap-x-3 p-2">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Correctif par Prestation")}</h2>
          </div>
          <CorrectifPerPrestation theme={theme} data={CorrectivesPerPrestation.data} />

        </div>
        <div className=' w-full rounded-xl bg-white  text-black dark:text-neutral-100 dark:bg-neutral-800 p-3 rounded-xl border border-gray-200 dark:border-neutral-700'>
          <div className="flex items-center gap-x-3 p-2">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Conssomable par Prestation")}</h2>
          </div>
          <ConssomablePerPrestation theme={theme} data={ConsomablesPerPrestation.data} />

        </div>
        <div className=' w-full rounded-xl bg-white  text-black dark:text-neutral-100 dark:bg-neutral-800 p-3 rounded-xl border border-gray-200 dark:border-neutral-700'>
          <div className="flex items-center gap-x-3 p-2">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Correctif par Quarter")}</h2>
          </div>
          <CorrectivesPerQuarter theme={theme} data={correctivesPerQuarter.data} />

        </div>
        <div className=' w-full rounded-xl bg-white  text-black dark:text-neutral-100 dark:bg-neutral-800 p-3 rounded-xl border border-gray-200 dark:border-neutral-700'>
          <div className="flex items-center gap-x-3 p-2">
            <h2 className="text-xl font-semibold text-gray-800 dark:text-white">{t("Preventif par Quarter")}</h2>
          </div>
          <PreventifPerQaurter theme={theme} data={preventifsPerQuarter.data} />

        </div>
      </div>



    </div>
  );
}

export default Dashboard;
