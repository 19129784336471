import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Input, Select, Loading } from '../../components'
import axiosInstance from '../../utils/axios/axiosConfig';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';


function FormAgences() {
    const { t } = useTranslation();

    const [done, setDone] = useState(false)
    const [zones, setZones] = useState([])
    const [loading, setLoding] = useState(false)
    const [departements, setDepartements] = useState([])

    const Types = [{ name: t('Clinique'), _id: 'Clinique' }, { name: t('Prive'), _id: 'Prive' }, { name: t('Etat'), _id: 'Etat' }]

    const [formData, setFormData] = useState({ name: null, phone: null, zone: null, departement: null, code: null })

    const { id } = useParams()

    useEffect(() => {
        getZones()
        if (id) {
            getAgencesToUpdate()
        } else {
            InitialiseAgences()
        }
    }, [done])

    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData({ ...formData, [e.target.name]: value });
    };

    const hanldleZoneChange = (e) => {
        const value = e.target.value;
        const zone = zones.find(item => item._id === value)
        let departements = []
        if (zone) {
            setFormData({ ...formData, [e.target.name]: value });
            zone.departements.forEach(item => {
                departements.push({ _id: item, name: item })
            })
        }

        setDepartements(departements)


    }


    const InitialiseAgences = () => {
        setFormData({ name: null, phone: null, zone: null, departement: null, code: null })
    }

    const getZones = async () => {
        try {
            setLoding(true)
            const response = await axiosInstance.get('/zones/search');
            const responseData = response.data

            if (responseData && responseData.statusCode === 200) {
                setZones(responseData.data)
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
        }
    };

    const getAgencesToUpdate = async () => {
        try {
            setLoding(true)
            const response = await axiosInstance.get(`/agences/get/${id}`);
            const responseData = response.data
            if (responseData && responseData.statusCode === 200) {
                setFormData(responseData.data)
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message)
            setLoding(false)
            console.error('Role creation failed:', error.response.data);
        }
    }




    const save = async (e) => {
        e.preventDefault();
        try {
            setLoding(true)
            let response = null
            if (id) {
                response = await axiosInstance.put(`/agences/update/${id}`, formData);
            } else {
                response = await axiosInstance.post('/agences/create', formData);
            }
            const responseData = response.data
            if (responseData && responseData.statusCode === 200) {
                if (id) {
                    setDone(!done)
                    toast.success(responseData.message);
                } else {
                    InitialiseAgences()
                    toast.success(responseData.message);
                    //console.log(responseData.message)
                }
            }
            setLoding(false)
        } catch (error) {
            toast.error(error.response.data.message);
            setLoding(false)
        }
    };






    return (

        <div className='relative w-full h-full'>



            {loading &&
                <Loading />
            }

            <div className='grid  my-14  md:px-44 px-4'>

                <div className="sm:flex sm:items-center mb-6 sm:justify-between">
                    <div>
                        <ol className="flex items-center whitespace-nowrap mb-5">
                            <li className="inline-flex items-center">
                                <Link to='/Agences' className="flex items-center text-sm text-gray-500 hover:text-blue-600 focus:outline-none focus:text-blue-600 dark:text-gray-500 dark:hover:text-blue-500 dark:focus:text-blue-500" href="#">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" className="lucide flex-shrink-0 me-3 size-4 lucide-lock-keyhole"><circle cx="12" cy="16" r="1" /><rect x="3" y="10" width="18" height="12" rx="2" /><path d="M7 10V7a5 5 0 0 1 10 0v3" /></svg>
                                    {t("Centres")}
                                </Link>
                                <svg className="flex-shrink-0 mx-2 overflow-visible size-4 text-gray-400 dark:text-gray-600" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="m9 18 6-6-6-6"></path>
                                </svg>
                            </li>

                            <li className="inline-flex items-center text-sm font-semibold text-gray-800 truncate dark:text-gray-200" aria-current="page">
                                {id ? t('new centre') : t('update centre')}
                            </li>
                        </ol>
                        <div className="flex items-center gap-x-3">
                            <h2 className="text-xl font-semibold text-gray-800 dark:text-white capitalize"> {id ? t('new centre') : t('update centre')}</h2>
                        </div>
                        <p className="mt-1 text-start text-sm text-gray-500 dark:text-gray-300">{t("Here you can add a new centre.")}</p>
                    </div>
                </div>
                <div className='w-full flex md:flex-row gap-5 flex-col '>

                    <div className=' w-full grid gap-3 p-6 border border-neutral-200 dark:border-neutral-700 bg-white dark:bg-neutral-800 rounded-2xl'>
                        <form action='' className='w-full '>
                            <div className='grid md:grid-cols-2 grid-cols-1 w-full place-items-center gap-6 md:p-5 p-3'>

                                <Input type='text' disabled={false} label={t("Name")} name="name" handleChange={handleChange} defaultV={formData.name || null} />

                                <Input type='text' disabled={false} label={t("Phone Number")} name="phone" handleChange={handleChange} defaultV={formData.phone || null} />

                                <Input type='text' disabled={false} label={t("Code")} name="code" handleChange={handleChange} defaultV={formData.code || null} />

                                <Select label={t("Zone")} disabled={false} options={zones} name="zone" handleChange={hanldleZoneChange} defaultV={formData.zone || null} />

                                <Select label={t("Departement")} disabled={departements.length > 0 ? false : true} options={departements} name="departement" handleChange={handleChange} defaultV={formData.departement || null} />


                                <Select label={t("Type")} disabled={false} options={Types} name="type" handleChange={handleChange} defaultV={formData.type || null} />


                                <div className='md:col-span-2 w-full flex items-center justify-end'>
                                    <div className="flex items-center mt-4 gap-x-3">
                                        <button onClick={(e) => save(e)} className="flex items-center justify-center  w-full px-6 py-3 font-[600] text-sm tracking-wide text-white transition-colors duration-200 bg-black dark:text-black rounded-lg shrink-0 sm:w-auto gap-x-2  dark:bg-neutral-100">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                            </svg>
                                            <span>{id ? 'Add Centre' : 'Add Centre'}</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default FormAgences